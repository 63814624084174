import React from "react";
import {Switch, Route, useLocation} from "react-router-dom";
import "../../../css/Routing.css";
import {useSelector} from "react-redux";

// content
import Home from "../../content/Home";
import Users from "../../content/Users";
import Project from "../../content/Project/mainPage";
import Projects from "../../content/Projects/index";
import CreateProject from "../../content/CreateProject";
import EditProject from "../../content/Project/editPage";
import SelfEditUser from "../../content/SelfEditUser";
import CreateUser from "../../content/CreateUser";
import Contacts from "../../content/Contacts";
import AddGbu from "../../content/Project/AddGbu";
import AddWorkType from "../../content/Project/AddWorkType";
import StopcardInfo from "../../content/Project/StopcardInfo";

import CreateContact from "../../content/CreateContact";
import Breadcrump from "./Breadcrump";
import SignGbu from "../../content/Project/SignGbu";

function Routing() {
    const showSidebar = useSelector((state) => state.showSidebar);
    const projects = useSelector((state) => state.userData.projects);
    const location = useLocation();
    const isAdmin = useSelector((state) => state.userData.admin);
    const user_inactive = useSelector((state) => state.userData.inactive);

    if (user_inactive !== true||isAdmin==true) {
        if (
            projects?.length === 0 &&
            location.pathname !== "/benutzer/bearbeiten/" &&
            !isAdmin
        )
            return (
                <main className={showSidebar ? "main__small" : ""}>
                    <div className="page__wrapper">
                        <div className="alert alert-danger text-center">
                            Sie sind derzeit keinem Projekt zugeteilt.
                        </div>
                    </div>
                </main>
            );

        return (
            <main className={showSidebar ? "main__small" : ""}>
                <Breadcrump/>
                <div className="page__wrapper">
                    <Switch>
                        <Route exact path="/kontakte" component={Contacts}/>

                        <Route exact path="/kontakte/anlegen" component={CreateContact}/>
                        <Route exact path="/projekte" component={Projects}/>
                        <Route exact path="/projekt/:slug" component={Project}/>
                        <Route
                            exact
                            path="/projekt/bearbeiten/:slug"
                            component={EditProject}
                        />
                        <Route exact path="/projekte/anlegen" component={CreateProject}/>
                        <Route exact path="/benutzer" component={Users}/>
                        <Route exact path="/benutzer/anlegen" component={CreateUser}/>
                        <Route exact path="/benutzer/bearbeiten" component={SelfEditUser}/>
                        <Route exact path="/gbu/anlegen/:slug" component={AddGbu}/>
                        <Route exact path="/gbu/work/anlegen/:slug" component={AddWorkType}/>
                        <Route exact path="/gbu/sign/:slug" component={SignGbu}/>
                        <Route exact path="/stopcard/info/:project_id/:slug" component={StopcardInfo}/>
                        <Route exact path="/" component={Home}/>
                    </Switch>
                </div>
            </main>
        );
    } else
        return (
            <main className={showSidebar ? "main__small" : ""}>
                <div className="page__wrapper">
                    <div className="alert alert-danger text-center">
                        Ihr Account wurde deaktiviert.
                    </div>
                </div>
            </main>
        );
}

export default Routing;
