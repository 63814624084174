import React from "react";
import "../../../css/Modal.css";
import {FaTimes} from "react-icons/fa";

function Modal({close, buttonFunc, contact}) {
    return (
        <div className="modal">
            <div className="modal__content">
                <div className="modal__header">
                    <h2 className="modal__title">Kontakt löschen</h2>
                    <FaTimes onClick={() => close()}/>
                </div>
                <div className="modal__body">
                    Möchten Sie den Kontakt{" "}
                    <b>
                        {contact.data.name}
                    </b>{" "}
                    wirklich löschen?
                    <br/>
                    <br/>
                    {contact.projects.length > 0 && (
                        <div>
                            <b>Achtung!</b> Dieser Kontakt befindet sich in folgenden
                            Projekten:
                            <ul className="modal__list">
                                {contact.projects.map((project) => (
                                    <li key={project.id}>
                                        {project.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="modal__footer">
                    <button
                        className="btn btn-primary"
                        onClick={() => buttonFunc(contact.data.id)}
                    >
                        Ja, löschen
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Modal;
