import { combineReducers } from "redux";
import userDataReducer from "./userData";
import selectedPageReducer from "./selectedPage";
import preloadDoneReducer from "./preloadDone";
import isLoggedInReducer from "./isLoggedIn";
import projectsReducer from "./projects";
import snackbarReducer from "./snackbar";
import showSidebarReducer from "./sidebar";

const allReducers = combineReducers({
  userData: userDataReducer,
  selectedPage: selectedPageReducer,
  preloadDone: preloadDoneReducer,
  isLoggedIn: isLoggedInReducer,
  adminProjects: projectsReducer,
  snackbar: snackbarReducer,
  showSidebar: showSidebarReducer,
});

export default allReducers;
