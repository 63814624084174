import React, { useEffect } from "react";
import "../../../css/Sidebar.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  FaUserFriends,
  FaUserPlus,
  FaHome,
  FaRegListAlt,
  FaRegAddressBook,
} from "react-icons/fa";
import { RiMailAddLine } from "react-icons/ri";
import { BiAddToQueue } from "react-icons/bi";

function Sidebar() {
  const showSidebar = useSelector((state) => state.showSidebar);
  const selectedPage = useSelector((state) => state.selectedPage);
  const isAdmin = useSelector((state) => state.userData.admin);

  useEffect(() => {
    const menuItems = document.querySelectorAll(".selected");
    menuItems.forEach((i) => i.classList.remove("selected"));
    const activeItem = document.querySelector(`#${selectedPage.id}`);
    activeItem?.classList.add("selected");
  }, [selectedPage]);

  return (
    <aside className={showSidebar ? "aside__small" : ""}>
      <nav className="sidebar__nav">
        <div className="sidebar__section">
          <div
            className={`sidebar__sectionTitle ${
              showSidebar ? `sidebar__sectionTitle--small` : ``
            }`}
          >
            Allgemein
          </div>
          <ul
            className={`sidebar__navList ${
              showSidebar ? `sidebar__navList--small` : ``
            }`}
          >
            <Link to="/">
              <li id="dashboard">
                <FaHome /> {!showSidebar && <span>Dashboard</span>}
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebar__section">
          <div
            className={`sidebar__sectionTitle ${
              showSidebar ? `sidebar__sectionTitle--small` : ``
            }`}
          >
            Projekte
          </div>
          <ul
            className={`sidebar__navList ${
              showSidebar ? `sidebar__navList--small` : ``
            }`}
          >
            <Link to="/projekte">
              <li id="project_overview">
                <FaRegListAlt /> {!showSidebar && <span>Übersicht</span>}
              </li>
            </Link>
            {isAdmin&&   <Link to="/projekte/anlegen">
              <li id="project_create" >
                <BiAddToQueue /> {!showSidebar && <span>Anlegen</span>}
              </li>
            </Link>}

          </ul>
        </div>

        <div className="sidebar__section">
          <div
            className={`sidebar__sectionTitle ${
              showSidebar ? `sidebar__sectionTitle--small` : ``
            }`}
          >
            Kontakte
          </div>
          <ul
            className={`sidebar__navList ${
              showSidebar ? `sidebar__navList--small` : ``
            }`}
          >
            <Link to="/kontakte">
              <li id="contacts">
                <FaRegAddressBook />{" "}
                {!showSidebar && <span>alle Kontakte</span>}
              </li>
            </Link>
            <Link to="/kontakte/anlegen">
              <li id="contact_create">
                <RiMailAddLine /> {!showSidebar && <span>neuer Kontakt</span>}
              </li>
            </Link>
          </ul>
        </div>
      </nav>
    </aside>
  );
}

export default Sidebar;
