import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SetSelectedPage,
  AddProjectData,
  OpenSnackbar,
} from "../../../adapters/redux/actions";
import config from "../../../adapters/url_config/config";

import { useForm } from "react-hook-form";
import functions from "../../../assets/DBCalls/functions";
import {Dropdown} from "primereact/dropdown";

function CreateProject() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.userData.projects);
  const user = useSelector((state) => state.userData);

  const [hasParent, setHasParent] = useState(false);
  const [projectSelection, setProjectSelection] = useState([]);
  const [dropValue, setDropValue] = useState({id: null});

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data, e) => {
    addProject(data.projectName, data.projectnumber, data.isActive, data.emergencyPhone);
    reset();
  };

  useEffect(() => {
    dispatch(
      SetSelectedPage({
        title: "Projekt anlegen",
        breadcrump: "Projekte / Anlegen",
        id: "project_create",
      })
    );
  }, []);

  useEffect(async () => {
    await functions.fetch('project?allProjects=true&withInactive=true&onlyParents=true').then(res => {
      let r = [];
      for (let i=0; i < res.length; i++)
      {
        r.push(res[i]);
      }
      setProjectSelection(r);
    });
  }, []);

  const createRandomId = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16).toUpperCase();
      }
    );
  };

  function addProject(projectname, projectnumber, isActive, emergency_phone) {
    const id = createRandomId();
    var role = config.plRole;

    const body = {
      id: id,
      name: projectname,
      parent_project_id: dropValue.id,
      state: null,
      alibi: projectnumber,
      is_inactive: isActive,
      emergency_phone: emergency_phone
    };

    console.log(body);

    functions.post("project", body)
      .then((addProjectData) => {
        //Project erstellt -> schreibe mich als PL in das Projekt
        if (addProjectData) {

          const addUserToProject = () => {
            functions.post('project/' + id+ '/user/' + user.id, {
              application_id: config.application_id,
              role_id: role
            }).then(async res => {
              if (res) {
                dispatch(OpenSnackbar("Projekt wurde hinzugefügt.", true));
              } else {
                alert(res.error)
              }
            })
          }
          addUserToProject();
        }
      });
  }

  return (
    <>
      <div className="createContact__container">
        <form className="createContact__form" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="projectName">Projektname</label>
          <input
            type="text"
            autoComplete="off"
            className={`form-control ${errors.projectName && "is-invalid"}`}
            {...register("projectName", {
              maxLength: {
                value: 100,
                message: "Projektname ist zu lang",
              },
              required: "Projektname eingeben",
            })}
          />
          {errors.projectName && (
            <div className="error-feedback">{errors.projectName.message}</div>
          )}
          <label htmlFor="projectnumber">Projektnummer</label>
          <input
            type="text"
            autoComplete="off"
            className={`form-control ${errors.projectnumber && "is-invalid"}`}
            {...register("projectnumber", {
              required: "Projektnummer eingeben.",
              pattern: {
                value: /^[0-9]+$/i,
                message: "Nur Zahlen erlaubt",
              },
            })}
          />
          {errors.projectnumber && (
            <div className="error-feedback">{errors.projectnumber.message}</div>
          )}

          <label htmlFor="emergencyPhone">Notfallnummer</label>
          <input
              type="text"
              autoComplete="off"
              className={`form-control ${errors.emergencyPhone && "is-invalid"}`}
              {...register("emergencyPhone", {
                required: "Notfallnummer eingeben.",
                pattern: {
                  value: /^[0-9]+$/i,
                  message: "Nur Zahlen erlaubt",
                },
              })}
          />
          {errors.emergencyPhone && (
              <div className="error-feedback">{errors.emergencyPhone.message}</div>
          )}

          <p style={{overflow: 'hidden'}}>
            <label htmlFor={'isActive'}  style={{float: 'left'}}>
              <input
                  type="checkbox"
                  defaultChecked={true}
                  id="isActive"
                  {...register("isActive", {})}
              />
              aktives Projekt?
            </label>
          </p>

          <p style={{overflow: 'hidden'}}>
            <label htmlFor={'subProj'}  style={{float: 'left'}}>
              <input
                  id={'subProj'}
                  type={'checkbox'}
                  checked={hasParent}
                  onChange={() => {
                    setHasParent(!hasParent);
                  }}
              />
              Handelt es sich um ein Unterprojekt?
            </label>
          </p>

          {hasParent &&
              <div>
                <label style={{marginBottom:'3px'}} htmlFor={'parent'}>Welchem Projekt so dieses Untergeordnet werden?</label>
                <Dropdown optionLabel={'name'} value={dropValue} options={projectSelection} onChange={(e) => {setDropValue(e.target.value); console.log(projectSelection);}} placeholder={'Projekt auswählen...'} />
              </div>
          }

          <input
            className="btn btn-primary"
            type="submit"
            value="Projekt erstellen"
          />
        </form>
      </div>
    </>
  );
}

export default CreateProject;
