import React, {useEffect, useRef, useState} from "react";
import functions from "../../../../assets/DBCalls/functions";
import {Accordion, AccordionTab} from 'primereact/accordion';
import UserDataTable from "./UserDataTable";
import {FaCheckCircle, FaTimesCircle, FaTrashAlt} from "react-icons/fa";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from 'primereact/inputtext';
import config from "../../../../adapters/url_config/config";
import {Link} from "react-router-dom";
import "../../../../css/Project.css";
import {ActivateProject, UpdateDisabledProjects} from "../../../../adapters/redux/actions";
import {useDispatch, useSelector} from "react-redux";

function ProjectDataTable(props) {
    const dispatch = useDispatch();
    const [subProjects, setSubProjects] = useState([]);
    const [projectStatusInactive, setProjectStatusInactive] = useState([]);
    const [classInactive, setClassInactive] = useState('');
    const op = useRef(null);
    const isMounted = useRef(false);
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [newSubProject, setNewSubproject] = useState('');

    const toggleProj = async (projectID, status) => {
        if (projectID) {
            let data = await functions.patch('project/' + projectID, status, projectID);
            if (data) {
                setProjectStatusInactive(prevState => ({...prevState, [projectID]: status}));

            }
        }
    };

    const deleteSubProject = async (rowData) => {
        await functions.delete('project/' + rowData.parent_project_id + '/sub/' + rowData.id, null, 'sicherEs').then(async res => {
            //console.log(res)
            if (!res.ok) {
                alert(' Dieses Unterprojekt enthält bereits Daten und kann nicht gelöscht werden')
            } else {
                // window.location.reload();
                await reloadProject();
            }
        })

    }


    const reloadProject = async (projectId) => {
        let data = await functions.fetch('project/' + props.projectId);
        setSubProjects(data.subprojects);
    }

    const mapAccordion = (project) => {
        let baseInformations = <div className='flex'>
            <div className='flex m-auto white-space-nowrap'>{project.name} </div>
        </div>;
        let header = <div className="project__edit">{baseInformations}<small><span
            className={'notbold'}>{gbuTemplate(project)}</span></small></div>;
        if (props.page == 'edit') {
            header = <div className="project__edit w-full">
                {baseInformations} {
                (!projectStatusInactive[project.id] ? (
                    <li className="deactivate" onClick={() => toggleProj(project.id, true)}><FaTimesCircle/></li>
                ) : (
                    <li className="activate" onClick={() => toggleProj(project.id, false)}><FaCheckCircle/>
                    </li>
                ))}
                <div className="flex w-full justify-content-end">
                    {gbuTemplate(project)}

                    <li className="activate" onClick={() => deleteSubProject(project)}><FaTrashAlt color='#666666'/>
                    </li>
                </div>
            </div>
        }


        return <AccordionTab header={header} key={project.id}>
            <UserDataTable id={project.id} page={props.page} gbuData={props.gbuData}/></AccordionTab>
    }

    const addSubProjectToProject = () => {
        functions.post('project/', {
            name: newSubProject,
            parent_project_id: props.projectId,
            is_inactive: true
        }).then(async res => {
            if (res.id) {
                // window.location.reload();
                await reloadProject();
            } else {
                alert(res)
            }
        })
    }

    const createAndDownloadPdf = async (projectId, gbuId) => {
        await functions.fetch('project/' + projectId + '/gbu/pdf/' + gbuId, 'sicherEs').then(res => {

            const linkSource = `data:application/pdf;base64,` + res;
            const downloadLink = document.createElement("a");
            const fileName = "Gefährdungsbeurteilung (GBU).pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        })
    }

    const gbuTemplate = (project) => {
        let template = '';
        //  let gbu = functions.fetch('project/' + projectId + '/gbu/last', 'sicherEs');
        let gbuUrl = '/gbu/anlegen/' + project.id;

        if (project.gbu !== null && project.gbu) {
            template =
                <ul className="project__edit"><Button icon="pi pi-file-pdf"
                                                      className="p-button-rounded p-button-secondary p-button-text p-0 h-auto"
                                                      aria-label="pdf"
                                                      onClick={() => createAndDownloadPdf(project.id, project.gbu.id)}/>
                    {props.page == 'edit' && <Link
                        to={gbuUrl}>
                        <li>Gefährdungsbeurteilung (GBU) ändern</li>
                    </Link>}</ul>
            ;
        } else {
            template =
                <ul className="project__edit">
                    <div className="m-auto">Keine Gefährdungsbeurteilung (GBU) hinterlegt</div>
                    {props.page == 'edit' && <Link to={gbuUrl}>
                        <li>Gefährdungsbeurteilung (GBU) anlegen</li>
                    </Link>}</ul>

        }
        return template;
    }

    useEffect(() => {
        let inactiveArray = [];
        for (var project of subProjects) {
            inactiveArray[project.id] = project.is_inactive;
        }
        setProjectStatusInactive(inactiveArray);
    }, [subProjects]);

    useEffect(() => {
        dispatch(UpdateDisabledProjects(projectStatusInactive))

    }, [projectStatusInactive]);

    useEffect(() => {
        if (newSubProject !== '') {
            setDisabledSubmit(false)
        } else {
            setDisabledSubmit(true)
        }
    }, [newSubProject])

    useEffect(async () => {

        const promises = props.subProjects.map(async project => {
            const gbu = await functions.fetch('project/' + project.id + '/gbu/last', 'sicherEs');
            project.gbu = gbu;
            return project;
        });

        const gbu = await Promise.all(promises)
        let sortedSubProj = (props.subProjects).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        setSubProjects(sortedSubProj);
        isMounted.current = true;
    }, []);


    return (<div><Accordion multiple>{subProjects.map(project => {
        return mapAccordion(project);
    })}</Accordion>
        {props.page == 'edit' &&
            <Button type="button" icon="pi pi-user" label={'Unterprojekt hinzufügen'}
                    onClick={(e) => op.current.toggle(e)}
                    aria-haspopup aria-controls="overlay_panel"/>}
        <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{width: '450px'}} className="overlaypanel-demo">
            <div className="p-fluid">
                <label htmlFor="projektname" className="block">Projektname</label>
                <InputText id="unterprojekt_eingabe" value={newSubProject}
                           onChange={(e) => setNewSubproject(e.target.value)}/>
                <Button label="Hinzufügen" disabled={disabledSubmit} onClick={addSubProjectToProject}/>
            </div>
        </OverlayPanel></div>);

}

export default ProjectDataTable;
