/*
 * OnlyOfficeEditor.tsx
 * Author: amusiol
 * Date: 04.07.2022
 *
 * Copyright: DMT GmbH & Co. KG
 */

import React, {useEffect, useState} from 'react';
import config from "../../../../adapters/url_config/config";

import {Button} from 'primereact/button';

export const OnlyOfficeEditor = (props) => {
    const editorId = 'placeholder';
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [editor, setEditor] = useState(null)
//let editor;
    useEffect(() => {
        //https://office.dmt-group.com/
        const resourceUrl = 'https://office.dmt.de/web-apps/apps/api/documents/api.js';
        const script = document.createElement('script');
        script.src = resourceUrl;
        script.async = true;
        script.onload = () => setScriptLoaded(true);
        document.head.appendChild(script);
        return () => {
            if (editor != null) {
                // @ts-ignore
                editor.destroyEditor();
            }

            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {

        const conf = {
            'document': {
                'fileType': 'docx',
                'key': props.workId, //'doc_' + new Date().getTime(), // uuid ''e40c4235-6cce-4ec9-b65e-f93dae69dfe3'
                'title': 'Document Title',
                'url': config.fetchSicherEs_Liveurl + 'template/doc/b7c99bc1-e5d6-455f-ac1c-3b62faa23591?download=true',
                'permissions': {
                    'comment': true,
                    'commentGroups': {
                        'edit': ['Group2', ''],
                        'remove': [''],
                        'view': ''
                    },
                    'copy': true,
                    'deleteCommentAuthorOnly': false,
                    'download': true,
                    'edit': true,
                    'editCommentAuthorOnly': false,
                    'fillForms': true,
                    'modifyContentControl': true,
                    'modifyFilter': true,
                    'print': true,
                    'review': true,
                    'reviewGroups': ['Group1', 'Group2', '']
                },
            },
            'documentType': 'word',
            'height': '100%',
            'token': config.onlyOffice_token,
            'type': 'desktop',
            'width': '100%',
            'editorConfig': {
                'callbackUrl': config.fetchSicherEs_Liveurl + 'saveOnlyOfficeDoc?id=' + props.workId,
                'createUrl': '',
                'lang': 'de',
                'customization': {
                    'plugins': true,
                    'zoom': 100,
                    'toolbarHideFileName': true,
                    'forcesave': true,
                    'anonymous':{request:false}
                }
            }
        };
        setEditor(new window.DocsAPI.DocEditor(editorId, conf));


    }, [scriptLoaded])


    return (<div id={editorId}/>);

};
