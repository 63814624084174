import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
  SetSelectedPage
} from "../../../adapters/redux/actions";
import UserDataTable from "./components/UserDataTable";
import BaseInformations from "./components/BaseInformations";
import ProjectDataTable from "./components/ProjectDataTable";
import ContactDataTable from "./components/ContactDataTable";
import {Button} from "primereact/button";
import ExportDialog from "./components/ExportDialog";
import functions from "../../../assets/DBCalls/functions";
import valueMapping from "../../../assets/General/valueMapping";
import {useParams} from "react-router-dom";
import StopkartenDataTable from "./components/StopkartenDataTable";
import GbuDataTable from "./components/GbuDataTable";
import LmraDataTable from "./components/LmraDataTable";
import generalFunctions from "../../../assets/General/generalFunctions";

function EditProject(props) {
    // const {slug} = props.match.params;
    const [dataProject, setProjectData] = useState(null);
    const [gbuData, setGbuData] = useState(null);
    const dispatch = useDispatch();

    const isAdmin = useSelector((state) => state.userData.admin);
    const [dialogDisplay, setDialogDisplay] = useState('initial');
    const [toogleVisibl, setToogleVisible] = useState(false);
    const {slug} = useParams();

    const fetchSignedGbu = async () => {
        let url = 'project/' + slug + '/gbuInfo';
        return await functions.fetch(url, 'sicherEs');
    }

  const exportData = () => {
    setDialogDisplay('change');
    setToogleVisible(!toogleVisibl);//Um change zu triggern, sonst nicht gebraucht
  }

  const testValidAccess=async()=>{
      let role = await generalFunctions.getRole(isAdmin, slug);
      //console.log(role)
  }

    useEffect(async () => {
        //await testValidAccess();
        dispatch(
            SetSelectedPage({
                title: "Projekt bearbeiten",
                breadcrump: "Projekt / Bearbeiten",
                id: "project-edit",
            })
        );
        let data = await functions.fetch('project/' + slug);
        setProjectData(data);

        let gbuCall = await fetchSignedGbu();
        if (gbuCall) {
            setGbuData(gbuCall)
        }
    }, []);

    if (!dataProject) return <div>Kein gültiges Project gefunden</div>;

    return (
        <div>
            <div className="card">
                <BaseInformations title={dataProject.name} id={slug}
                                  inactive={dataProject.is_inactive} role={dataProject}
                                  isAdmin={isAdmin} number={dataProject.alibi} page={'edit'}/>
                <div className="card">
                    <div className="card__header">Benutzer</div>
                    <div className="card__body">
                        <div>
                            <div className="card">
                                <UserDataTable id={slug} page={'edit'} gbuData={gbuData}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card__header">Unterprojekte</div>
                    <div className="card__body">
                        <ProjectDataTable subProjects={dataProject.subprojects} page={'edit'} projectId={slug} gbuData={gbuData}/>
                    </div>
                </div>
                <div className="card">
                    <div className="card__header">Kontakte</div>
                    <div className="card__body">
                        <ContactDataTable id={slug} page={'edit'}/>
                    </div>
                </div>
                <div className="card">
                    <div className="card__header">Gefährdungsbeurteilungen</div>
                    <div className="card__body">
                        <div className="card__body">
                            <GbuDataTable id={slug} page={'edit'}/>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card__header">Erfasste Stopkarten</div>
                    <div className="card__body">
                        <StopkartenDataTable id={slug} page={'edit'}/>
                    </div>
                </div>
                <div className="card">
                    <div className="card__header">Erfasste Last Minute Risk Analyse (LMRA)</div>
                    <div className="card__body">
                        <LmraDataTable id={slug}/>
                    </div>
                </div>
                <p><Button label="Unterlagen exportieren" className="p-button-outlined" aria-label="exportieren"
                           onClick={() => exportData()}/></p>
                <ExportDialog buttonaction={dialogDisplay} toogleVisible={toogleVisibl} projectId={dataProject.id}
                              projectName={dataProject.name}/>
            </div>
        </div>
    );
}

export default EditProject;
