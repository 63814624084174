import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    SetSelectedPage,
    EditUserData,
    OpenSnackbar,
} from "../../../adapters/redux/actions";
import {useForm} from "react-hook-form";
import "../../../css/CreateContact.css";
import config from "../../../adapters/url_config/config";
import functions from "../../../assets/DBCalls/functions";

function SelfEditUser() {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues: {
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
            phone: userData.phone,
            company: userData.company,
            position: userData.position,
        },
    });
    const onSubmit = (data, e) => {
       // console.log(data);
        editUser(
            data.firstName,
            data.lastName,
            data.email,
            data.phone,
            data.company,
            data.position
        );
    };

    function editUser(firstName, lastName, email, phone, company, position) {

        functions.put('updateKeycloakUser', JSON.stringify({
            "id": userData.id,
            "firstname": firstName,
            "lastname": lastName,
            "email": email
        })).then(res => {
           // console.log(res)
            if (res) {
                functions.put('user/' + userData.id, JSON.stringify({
                    "keycloak_id": userData.id,
                    "position_company": position,
                    "phone": phone,
                    "company": company
                })).then(res1 => {
                   // console.log(res1)
                    if (res1) {
                        dispatch(OpenSnackbar("Änderungen gespeichert."));
                        dispatch(
                            EditUserData({
                                first_name: firstName,
                                last_name: lastName,
                                mail: email,
                                phone: phone,
                                position: position,
                                company: company,
                            })
                        );
                    }
                })
            }
        });
    }

    useEffect(() => {
        dispatch(
            SetSelectedPage({
                title: "Benutzer bearbeiten",
                breadcrump: "Benutzer / Bearbeiten",
                id: "user-selfedit",
            })
        );
        //console.log(userData)
    }, []);
    // console.log(userData);
    return (
        <>
            <div className="createContact__container">
                <form className="createContact__form" onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="firstname">Vorname</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.firstName && "is-invalid"}`}
                        {...register("firstName", {
                            maxLength: {
                                value: 50,
                                message: "Vorname zu lang",
                            },

                            required: "Vorname eingeben",
                            pattern: {
                                value: /^[a-zäöüß \-]+$/i,
                                message: "Nur Buchstaben erlaubt",
                            },
                        })}
                    />
                    {errors.firstName && (
                        <div className="error-feedback">{errors.firstName.message}</div>
                    )}
                    <label htmlFor="lastName">Nachname</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.lastName && "is-invalid"}`}
                        {...register("lastName", {
                            maxLength: {
                                value: 50,
                                message: "Nachname zu lang",
                            },
                            required: "Nachname eingeben.",
                            pattern: {
                                value: /^[a-zäöüß \-]+$/i,
                                message: "Nur Buchstaben erlaubt",
                            },
                        })}
                    />
                    {errors.lastName && (
                        <div className="error-feedback">{errors.lastName.message}</div>
                    )}
                    <label htmlFor="email">E-Mail</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.email && "is-invalid"}`}
                        {...register("email", {
                            maxLength: {
                                value: 100,
                                message: "E-Mail zu lang",
                            },
                            required: "E-Mail eingeben",
                            pattern: {
                                value:
                                    /^[a-z0-9._-]+(?:\.[a-z0-9._-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                                ///^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Ungültige E-Mail-Adresse",
                            },
                        })}
                    />
                    {errors.email && (
                        <div className="error-feedback">{errors.email.message}</div>
                    )}
                    <label htmlFor="phone">Telefonnummer</label>
                    <input
                        type="tel"
                        autoComplete="off"
                        className={`form-control ${errors.phone && "is-invalid"}`}
                        {...register("phone", {
                            maxLength: {
                                value: 20,
                                message: "Telefonnummer zu lang",
                            },
                            required: "Telefonnummer eingeben",
                            pattern: {
                                value:
                                    /^([+](\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,10}$/im,
                                message: "Ungültige Telefonnummer",
                            },
                        })}
                    />
                    {errors.phone && (
                        <div className="error-feedback">{errors.phone.message}</div>
                    )}
                    <label htmlFor="company">Firmenname</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.company && "is-invalid"}`}
                        {...register("company", {
                            maxLength: {
                                value: 50,
                                message: "Firmenname zu lang",
                            },
                            required: "Firmenname eingeben.",
                        })}
                    />
                    {errors.company && (
                        <div className="error-feedback">{errors.company.message}</div>
                    )}
                    <label htmlFor="position">Positionsbezeichnung</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.position && "is-invalid"}`}
                        {...register("position", {
                            maxLength: {
                                value: 100,
                                message: "Positionsbezeichnung zu lang",
                            },
                            required: "Positionsbezeichnung eingeben.",
                        })}
                    />
                    {errors.position && (
                        <div className="error-feedback">{errors.position.message}</div>
                    )}

                    <input
                        className="btn btn-primary"
                        type="submit"
                        value="Änderungen speichern"
                    />
                </form>
            </div>
        </>
    );
}

export default SelfEditUser;
