import React, {useEffect, useRef, useState} from "react";
import functions from "../../../../assets/DBCalls/functions";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import {Dropdown} from "primereact/dropdown";
import valueMapping from "../../../../assets/General/valueMapping";

function ContactDataTable(props) {
    const [dataUsers, setDataUsers] = useState([]);
    const [choosenContact, setChoosenContact] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const isMounted = useRef(false);
    const op = useRef(null);

    const fetchDataContacts = async (id) => {
        let url = 'project/'+id+'/contact';
        let data = await functions.fetch(url);
        setDataUsers(data)
    };


    const addContactToProject=()=>{
        functions.put('project/'+props.id+'/contact/'+choosenContact+'/add', ).then(async res => {
           if(res) {
               await fetchDataContacts(props.id);
               await callContactData();
               isMounted.current=false;
           }else{
               alert(res.error)
           }
        })
    }


    const callContactData=async ()=>{
        let contacts=await functions.fetch('project/'+props.id+'/contact/noproject');
        setContacts(contacts);
    }

    useEffect(() => {
        if (choosenContact !== '') {
            setDisabledSubmit(false)
        } else {
            setDisabledSubmit(true)
        }
    }, [choosenContact])

    useEffect(async() => {
        await fetchDataContacts(props.id);
        await callContactData();
        isMounted.current = true;
    },[]);

    return(  <div><DataTable className="card__table hovereffect" value={dataUsers} responsiveLayout="scroll">
        <Column field="name" header="Name"/>
        <Column field="email" header="Email"/>
        <Column field="phone" header="Tel."/>
        <Column field="position" header="Position"/>
        <Column field="company" header="Firma"/>
    </DataTable>
    {props.page == 'edit' &&
    <div>
        <Button type="button" icon="pi pi-user" label={'Kontakt hinzufügen'}
                onClick={(e) => op.current.toggle(e)}
                aria-haspopup aria-controls="overlay_panel"/>

        <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{width: '450px'}}
                      className="overlaypanel-demo">

            <div className="p-fluid">
                <Dropdown value={choosenContact} options={contacts} optionValue={'id'} required={true}
                          optionLabel={'name'}
                          onChange={(e) => setChoosenContact(e.value)} placeholder="Wählen Sie einen Kontakt"/><br/>
                <Button label="Hinzufügen" disabled={disabledSubmit} onClick={addContactToProject}/>
            </div>
        </OverlayPanel></div>}</div>
    )
}

export default ContactDataTable;