import Keycloak from "keycloak-js";


let keycloakConfig = {
  url: "https://sec.es.dmt-group.dev/",
  realm: "coreES",
  clientId: "sicherES-web",
};
/*let keycloakConfig = {
  url: "https://rest.dmt.de/auth",
  realm: "master",
  clientId: "argus-user-management",
};*/

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
