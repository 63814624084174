import {Link, useHistory} from "react-router-dom";
import {FaCheckCircle, FaEdit, FaTimesCircle, FaArrowCircleLeft} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import functions from "../../../../assets/DBCalls/functions";
import {ActivateProject, DeactivateProject} from "../../../../adapters/redux/actions";
import {useDispatch} from "react-redux";
import {Button} from 'primereact/button';
import generalFunctions from "../../../../assets/General/generalFunctions";


function BaseInformations(props) {
    const [headerTitle, setHeaderTitle] = useState('');
    const [showEditButton, setShowEditButton] = useState(false);
    const [toogleProjButton, setToogleProjButton] = useState(false);
    const [editUrl, setEditUrl] = useState('');
    const [gbuUrl, setGbuUrl] = useState('');
    const [projectStatusInactive, setProjectStatusInactive] = useState(true);
    const [classInactive, setClassInactive] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [number, setNumber] = useState('');
    const [userRole, setUserRole] = useState('');
    const [gbu, setGbu] = useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    // const [dataProject, setProjectData] = useState(null);

    const getRole = async () => {

        let role = await generalFunctions.getRole(props.isAdmin, props.id);
       // console.log(role)
        setUserRole(role);
        editButton(role);
        projButton(role, props.page);
    }

    const editButton = (role) => {

        let buttonValue;
        switch (role) {
            case 'Admin':
            case 'Project Manager':
                buttonValue = true;
                break;
            default:
                buttonValue = false;
                break;
        }
        buttonValue = props.page == 'edit' ? false : buttonValue;
        setShowEditButton(buttonValue);
    }

    const projButton = (role, page) => {
        let bool;
        switch (role) {
            case 'Admin':
            case 'Project Manager':
                bool = true;
                break;
            default:
                bool = false;
                break;
        }

        // bool = page == 'show' ? bool : false;
        setToogleProjButton(bool);
    }

    const toggleProj = async (projectID, status) => {
        if (projectID) {
            let data = await functions.patch('project/' + projectID, status, projectID);
            if (data) {
                setProjectStatusInactive(!projectStatusInactive);
            }
        }
    };

    const inactiveClassname = () => {
        let badge = projectStatusInactive == true
            ? " badge-danger"
            : " badge-success";

        setClassInactive('user__indicator' + badge);
    }

    const getGBU = async (projectId) => {
        await functions.fetch('project/' + projectId + '/gbu/last', 'sicherEs').then(res => setGbu(res))
    }

    const createAndDownloadPdf = async () => {
        await functions.fetch('project/' + props.id + '/gbu/pdf/' + gbu.id, 'sicherEs').then(res => {

            const linkSource = `data:application/pdf;base64,` + res;
            const downloadLink = document.createElement("a");
            const fileName = "Gefährdungsbeurteilung (GBU).pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        })
    }

    const gbuTemplate = () => {
        let template = '';
        if (gbu !== null) {
            template = <a>
                <ul className="project__edit"><Button icon="pi pi-file-pdf"
                                                      className="p-button-rounded p-button-secondary p-button-text p-0 h-auto"
                                                      aria-label="pdf" onClick={createAndDownloadPdf}/>{props.page=='edit'&&<Link
                    style={{margin: 'auto'}}
                    to={gbuUrl}>
                    <li className="p-0 ml-2">Gefährdungsbeurteilung (GBU) ändern</li>
                </Link>}</ul>
            </a>;
        } else {
            template = <a>
                <ul className="project__edit">
                    <div> Keine Gefährdungsbeurteilung (GBU) hinterlegt</div>
                    {props.page=='edit'&& <Link to={gbuUrl}>
                        <li className="p-0 ml-2">anlegen</li>
                    </Link>}</ul>
            </a>
        }
        return template;
    }

    useEffect(async () => {
        setHeaderTitle(props.title);
        setEditUrl('/projekt/bearbeiten/' + props.id);
        setGbuUrl('/gbu/anlegen/' + props.id);
        setProjectStatusInactive(props.inactive);
        setIsAdmin(props.isAdmin);
        setNumber((props.number))


        await getRole();
        await getGBU(props.id);
    }, []);


    useEffect(() => {
        inactiveClassname()
    }, [projectStatusInactive])
    return (
        <div className="card">
            <div className="card__header">
                <b>{headerTitle}</b>
                <ul className="project__edit">
                    {props.page == 'show' && (showEditButton || isAdmin) && (
                        <Link to={editUrl}>
                            <li><FaEdit title="bearbeiten"/></li>
                        </Link>
                    )}
                    {props.page == 'edit' && <li onClick={history.goBack}><FaArrowCircleLeft/></li>}
                    {toogleProjButton &&
                    (projectStatusInactive ? (
                        <li className="activate" onClick={() => toggleProj(props.id, false)}><FaCheckCircle/></li>
                    ) : (
                        <li className="deactivate" onClick={() => toggleProj(props.id, true)}><FaTimesCircle/></li>
                    ))}

                </ul>
            </div>
            <div className="card__body">
                <ul className="card__list">
                    <li className="card__item small__list">
                        <span className="list_attribute">Status:</span>
                        <div className={classInactive}/>
                    </li>
                    <li className="card__item small__list">
                        <span className="list_attribute">Projektnummer:</span>
                        {number}
                    </li>
                    <li className="card__item small__list">
                        <span className="list_attribute">Gefährdungsbeurteilungen:</span>{gbuTemplate()}

                    </li>
                    <li className="card__item small__list">
                        <span className="list_attribute">Meine Rolle:</span>
                        {userRole}
                    </li>
                </ul>
            </div>
        </div>)
        ;
}

export default BaseInformations;
