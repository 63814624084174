import React, {useEffect, useState, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    SetSelectedPage
} from "../../../adapters/redux/actions";
import {Link, useHistory, useParams} from "react-router-dom";
import "../../../css/Gbu.css";
import functions from "../../../assets/DBCalls/functions";
import {Tree} from 'primereact/tree';
import {ContextMenu} from 'primereact/contextmenu';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {FaArrowCircleLeft} from "react-icons/fa";


function AddGbu() {
    const dispatch = useDispatch();
    const {slug} = useParams();
    const history = useHistory();
    const [projectName, setProjectName] = useState([]);
    const [subProjectName, setSubProjectName] = useState([]);
    const [dangerousWorks, setDangerousWorks] = useState([]);
    const previewRef = useRef(null);
    const formRef = useRef(null);

    const [nodes, setNodes] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedNodeKey, setSelectedNodeKey] = useState(null);
    const [selectedWork, setSelectedWork] = useState(null);
    const [workTypeUrl, setWorkTypeUrl] = useState('');
    const [selectedDangerousWork, setSelectedDangerousWork] = useState(null);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [dataToSave, setDataToSave] = useState(null);
    const cm = useRef(null);

    const createAndDownloadPdf = async () => {
        await functions.fetch('work/pdf/' + selectedNodeKey , 'sicherEs').then(res => {

            const linkSource = `data:application/pdf;base64,` + res;
            const downloadLink = document.createElement("a");
            const fileName = "Gbu.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        })
    }

    const menu = [
        {
            label: 'Pdf',
            icon: 'pi pi-file-pdf',
            command: async () => {
               await createAndDownloadPdf();
            },
        },
    ];

    const getDangerousWork = async () => {

        await functions.fetch('dangerous_work', 'sicherEs').then(res => setDangerousWorks(res));
    }


    const previewGBU = async () => {

        let sWork = [];
        let alreadySet = [];

        let selectedNodesArray = [];
        for (const selected of Object.keys(selectedWork)) {
            let selW = selectedWork[selected];
            if (!selected.includes('workspace') && selW.checked) {
                if(!sWork.includes(selected)){
                    sWork.push(selected)
                }
            }
            if (selected.includes('workspace')) {
                let foundNode = nodes.find(node => node.key == selected);
                if (foundNode) {
                    selectedNodesArray.push(Object.assign({}, foundNode));
                }
                let splitSelected = selected.split('_');
                if(!sWork.includes(splitSelected[1])){
                    sWork.push(splitSelected[1])
                }


            }
        }

        for (const sElem of sWork) {
            for (const selectedNode of selectedNodesArray) {
                if (selectedNode && selectedNode.children) {
                    let found = selectedNode.children.find(child => child.key == sElem);
                    if (found && !alreadySet.includes(sElem)) {
                        selectedNode.selectedChildren = selectedNode.selectedChildren ? selectedNode.selectedChildren : [];
                        let index = 'work_' + selectedNode.selectedChildren.length;
                        found[index] = found.label;
                        selectedNode.selectedChildren.push(found);
                        selectedNode[index] = found.label;
                        alreadySet.push(sElem);
                    }
                }
            }
        }
        setSelectedNodes(selectedNodesArray);

        createSavingData(sWork);
        toogleDivs('none', 'block');
    }


    const createSavingData = (sWork) => {
        let dWork = [];
        for (const dangerous of dangerousWorks) {
            dWork.push(dangerous.id);
        }
        let data = {
            project_id: slug,
            dangerous_work: dWork,
            work: sWork,
            pdf_values: {title: new Date()}
        };

        setDataToSave(data);
    }

    const saveGBU = async () => {
        await functions.post('project/' + slug + '/gbu', dataToSave, 'sicherEs').then(res => {
            if (res) {
                history.goBack()
            }
        })
    }

    const getProject = async () => {
        await functions.fetch('project/' + slug).then(async res => {

            if (res.parent_project_id) {
                await functions.fetch('project/' + res.parent_project_id).then(
                    res1 => {
                        setProjectName(res1.name);
                        setSubProjectName(res.name);
                    }
                )
            } else {
                setProjectName(res.name);
            }
        })
    }

    const getWorkspaces = async () => {
        await functions.fetch('work_type/?project_id=' + slug, 'sicherEs').then(async res => {
            if (res.length > 0) {
                let dataArray = [];
                for (const workspace of res) {
                    let childArray = [];
                    for (const work of workspace.worktype_work) {
                        let elem = {
                            "key": work.id,
                            "label": work.name,
                            "data": "Work Folder",
                            "icon": "pi pi-fw pi-cog",
                        }
                        childArray.push(elem);
                    }
                    let workSp = {
                        "key": 'workspace_' + workspace.id,
                        "label": workspace.name,
                        "data": "Documents Folder",
                        "icon": "pi pi-fw pi-folder",
                        "children": childArray
                    };
                    dataArray.push(workSp);
                }
                setNodes(dataArray);
            }
            // setWorkspaceButtons(res)
        })
    }

    const getWorkRowLenght = () => {
        let childLengh = selectedNodes.map(row => {
            if (row.selectedChildren) {
                return row.selectedChildren.length
            }
            return 0;
        });
        return Math.max.apply(Math, childLengh);
    }

    const generateAppColumns = () => {
        let lenght = getWorkRowLenght();
        let columns = [];
        for (var i = 0; i < lenght; i++) {
            let fieldName = 'work_' + i;
            let field = <Column field={fieldName} header="Tätigkeit"></Column>;
            columns.push(field);
        }
        return columns;
    }

    const toogleDivs = (formValue, previewValue) => {
        previewRef.current.style.display = previewValue;
        formRef.current.style.display = formValue;
    }


    useEffect(async () => {
        await getProject();
        await getWorkspaces();
        await getDangerousWork();
        setWorkTypeUrl('/gbu/work/anlegen/' + slug);

        dispatch(
            SetSelectedPage({
                title: "Gefährdungsbeurteilung (GBU) anlegen",
                breadcrump: "Projekt / Gefährdungsbeurteilung (GBU) ",
                id: "project_gbu",
            })
        );
    }, []);

    return (<div>

            <div className="card">
                <div className="card__header">Ausgewähltes Projekt<ul className="project__edit"><li onClick={history.goBack}><FaArrowCircleLeft/></li></ul></div>
                <div className="card__body"><b>Projektname:</b> {projectName}
                    <b> Unterprojekt:</b> {subProjectName}</div>
            </div>
            <div className='gbuForm' ref={formRef}>
                <div className="card">
                    <div className="card__header">Betroffene Arbeitsbereiche</div>
                    <div className="card__body">
                        <ContextMenu model={menu} ref={cm} onHide={() => setSelectedNodeKey(null)}/>
                        <Tree value={nodes} expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)}
                              contextMenuSelectionKey={selectedNodeKey}
                              onContextMenuSelectionChange={event => setSelectedNodeKey(event.value)}
                              onContextMenu={event => cm.current.show(event.originalEvent)}
                              selectionMode="checkbox" selectionKeys={selectedWork}
                              onSelectionChange={e => setSelectedWork(e.value)}/></div>
                    <a>
                        <ul className="project__edit"><Link to={workTypeUrl}>
                            <li>Neue Tätigkeit anlegen</li>
                        </Link></ul>
                    </a>
                </div>
                <div className="card">
                    <div className="card__header">Gefährliche Arbeiten (wenn vorhanden):</div>
                    <div className="card__body">
                        <DataTable value={dangerousWorks} selectionMode="checkbox"
                                   selection={selectedDangerousWork}
                                   onSelectionChange={e => setSelectedDangerousWork(e.value)} dataKey="id"
                                   responsiveLayout="scroll">
                            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                            <Column field="name" header="Alle Arbeiten"></Column>
                        </DataTable>
                    </div>
                </div>
                <div className="card">
                    <Button label="Vorschau zeigen" onClick={previewGBU}/>
                </div>
            </div>
            <div className='gbuPreview' ref={previewRef}>
                <div className="card">
                    <DataTable value={selectedNodes} showGridlines responsiveLayout="scroll">
                        <Column field="label" header="Arbeitsbereich"></Column>
                        {generateAppColumns()}
                    </DataTable>
                </div>
                <br/>

                <div>
                    <Toolbar left={<Button label="Zurück" onClick={() => {
                        toogleDivs('block', 'none')
                    }}/>} right={<Button label="Gefährdungsbeurteilung (GBU) speichern" onClick={saveGBU}/>}/>
                </div>
            </div>
        </div>
    )
}

export default AddGbu;