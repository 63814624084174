import React, {useEffect, useState} from "react";
import functions from "../../../../assets/DBCalls/functions";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {FaTrashAlt} from "react-icons/fa";
import {Button} from "primereact/button";

function GbuDataTable(props) {
    const [dataGbu, setDataGbu] = useState([]);

    const fetchDataGbu = async (id) => {
        let url = 'project/'+id+'/gbu';
        let data = await functions.fetch(url, 'sicherEs');
        data.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
        setDataGbu(data)
    };

    const gbuCreatedTemplate = (rowData) => {

        let dateDB=rowData.created_at;
        let date=new Date(dateDB);

        return (
            <React.Fragment>
               {date.toLocaleDateString('de-DE')} {date.toLocaleTimeString('de-DE')}
            </React.Fragment>
        );
    }

    const createAndDownloadPdf = async (id) => {
        await functions.fetch('project/' + props.id + '/gbu/pdf/' + id, 'sicherEs').then(res => {

            const linkSource = `data:application/pdf;base64,` + res;
            const downloadLink = document.createElement("a");
            const fileName = "Gbu.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        })
    }

    const gbuTemplate=(rowData)=>{
        return (
            <React.Fragment>
                GBU_{rowData.project_name}
            </React.Fragment>
        );
    }
    const pdfTemplate=(rowData)=>{
        return (
            <React.Fragment>
              <Button icon="pi pi-file-pdf"
                                                   className="p-button-rounded p-button-secondary p-button-text p-0"
                                                   aria-label="pdf" onClick={() => {
                createAndDownloadPdf(rowData.id)
            }}/>
            </React.Fragment>
        );
    }

    const removeGbu = async (gbuID) => {
        if (gbuID) {
            await functions.delete('project/'+props.id+'/gbu/'+gbuID+'/delete', null,'sicherEs').then(async res => {
                if (res) {
                  // window.location.reload();
                    await fetchDataGbu(props.id);
                }
            })
        }
    };

    const deletedTemplate=(rowData)=>{
        return (
            <React.Fragment>
                <div className="project__edit">
                    <li className="activate" onClick={() => removeGbu(rowData.id)}><FaTrashAlt  color='#666666'/></li>
                </div>
            </React.Fragment>
        );
    }

    useEffect(async() => {
        await fetchDataGbu(props.id);
    },[]);

    return(  <DataTable className="card__table hovereffect" value={dataGbu} responsiveLayout="scroll">
        <Column field="name" header="Name" body={gbuTemplate}/>
        <Column field="file" header="" body={pdfTemplate}></Column>
        <Column field="created_at" header="erfasst am" body={gbuCreatedTemplate}/>
        {props.page=='edit'&&<Column field="" header="" body={deletedTemplate}/>}
    </DataTable>)
}

export default GbuDataTable;
