import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SetSelectedPage, OpenSnackbar, EditUserData} from "../../../adapters/redux/actions";
import {FaEdit, FaTimesCircle, FaCheckCircle} from "react-icons/fa";
import {RiDeleteBin6Line} from "react-icons/ri";
import config from "../../../adapters/url_config/config";
import Modal from "./Modal";
import functions from "../../../assets/DBCalls/functions";

function Users() {
    const token = localStorage.getItem("keycloak-token");
    const dispatch = useDispatch();
    const isAdmin = useSelector((state) => state.userData.admin);
    const uUserId = useSelector((state) => state.userData.id);

    const [users, setUsers] = useState([]);
    const [dataFiltered, setDataFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editRow, setEditRow] = useState(null);

    const handleSearch = (input) => {
        const search = users.filter(
            (user) =>
                (user.first_name && user.first_name.toLowerCase().includes(input.toLowerCase())) ||
                (user.last_name && user.last_name.toLowerCase().includes(input.toLowerCase())) ||
                (user.company && user.company.toLowerCase().includes(input.toLowerCase()))
        );
        setDataFiltered(search);
    };

    const fetchUser = () => {
        setIsLoading(true);
        setHasError(false);

        functions.fetch('user')
            // .then((response) => response.json())
            .then((data) => {
                if (data) {
                    data.sort((a, b) => (a.last_name > b.last_name ? 1 : -1));
                    setUsers(data.filter((user) => user.id !== uUserId));
                    setDataFiltered(data.filter((user) => user.id !== uUserId));
                } else {
                    setHasError(true);
                }
                setIsLoading(false);
            });
    };

    const closeModal = () => {
        document.body.style.overflow = "";
        setOpenModal(false);
    };

    const saveEditedRow = (userId) => {
        // console.log(userId);
        // console.log(dataFiltered);
        if (userId) {
            const first_name = document.getElementById(
                "editUser_table_first_name"
            ).value;
            const last_name = document.getElementById(
                "editUser_table_last_name"
            ).value;
            // const mail = document.getElementById("editUser_table_mail").value;
            const phone = document.getElementById("editUser_table_phone").value;
            const position = document.getElementById("editUser_table_position").value;
            const company = document.getElementById("editUser_table_company").value;
            const admin = document.getElementById("editUser_table_admin").checked;
            if (
                // mail.length === 0 ||
                first_name.length === 0 ||
                last_name.length === 0
            ) {
                console.log("Leerefelder FIELDS");
            } else {
                functions.put('updateKeycloakUser', JSON.stringify({
                    "id": userId,
                    "firstname": first_name,
                    "lastname": last_name,
                 //   "email": mail
                })).then(res => {
                    if (res) {
                        functions.put('user/' + userId, JSON.stringify({
                            "keycloak_id": userId,
                            "position_company": position,
                            "phone": phone,
                            "company": company
                        })).then(res1 => {
                            if (res1) {

                                dispatch(OpenSnackbar("Änderungen gespeichert. Bitte Seite neu laden um Ansicht aufzufrischen!"));
                                setUsers(
                                    users.map((user) =>
                                        user.id === userId
                                            ? {
                                                ...user,
                                                first_name: first_name,
                                                last_name: last_name,
                                                // mail: mail,
                                                phone: phone,
                                                position: position,
                                                company: company,
                                                admin: admin,
                                            }
                                            : user
                                    )
                                );
                            }
                        })
                    }
                });
            }
        }
    };
    const handleEditRow = (row) => {
        setEditRow(row);
    };

    const table=()=>{

    }
    useEffect(() => {
        dispatch(
            SetSelectedPage({
                title: "Benutzerverwaltung",
                breadcrump: "Benutzer / Verwaltung",
                id: "user-management",
            })
        );
        fetchUser();
    }, []);
    useEffect(()=>{
        setEditRow(null);
        },[users])

    if (isLoading) return <p>Lade Benutzerliste...</p>;
    if (hasError)
        return (
            <p>
                Es ist ein Fehler aufgetretet. Bitte wenden Sie sich an einen
                Administrator.
            </p>
        );

   // console.log(dataFiltered);
    return (
        <>
            {/*{openModal && selectedUser && (*/}
            {/*    <Modal close={closeModal} buttonFunc={toggleUser} user={selectedUser}/>*/}
            {/*)}*/}
            <div className="card">
                <div className="card__header">
                    <input
                        autoComplete="off"
                        type="text"
                        id="user-management-search"
                        placeholder="Suche einen Benutzer..."
                        onChange={(e) => handleSearch(e.target.value)}
                        className="card__userSearch form-control"
                    />
                </div>
                <div className="card__body">
                    <table className="card__table hovereffect">
                        <thead>
                        <tr>
                            {isAdmin && <th></th>}
                            <th>Name</th>
                            <th>Email</th>
                            <th>Tel.</th>
                            <th>Position</th>
                            <th>Firma</th>
                            {isAdmin && <th>Argus Admin</th>}
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataFiltered.map((user, index) =>
                            editRow === index ? (
                                <tr key={index}>
                                    <td>
                                        <div
                                            className={`user__indicator ${
                                                user.enabled === false
                                                    ? "badge-danger"
                                                    : "badge-success"
                                            }`}
                                            title="Accountstatus"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            id="editUser_table_first_name"
                                            defaultValue={user.first_name}
                                        />
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            id="editUser_table_last_name"
                                            defaultValue={user.last_name}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            id="editUser_table_mail"
                                            defaultValue={user.email}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            id="editUser_table_phone"
                                            defaultValue={user.phone}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            id="editUser_table_position"
                                            defaultValue={user.position_company}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            id="editUser_table_company"
                                            defaultValue={user.company}
                                        />
                                    </td>
                                    {isAdmin && (
                                        <td>
                                            <input
                                                type="checkbox"
                                                id="editUser_table_admin"
                                                checked={user.isAdmin}
                                                readOnly={true}
                                            />
                                        </td>
                                    )}
                                    <td>
                                        <ul className="project__edit">
                                            <li
                                                onClick={() => saveEditedRow(user.id)}
                                                className="activate"
                                            >
                                                <FaCheckCircle title="speichern"/>
                                            </li>
                                            <li
                                                onClick={() => setEditRow(null)}
                                                className="deactivate"
                                            >
                                                <FaTimesCircle title="Abbrechen"/>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            ) : (
                                <tr key={user.id}>
                                    <td>
                                        <div
                                            className={`user__indicator ${
                                                user.enabled === false
                                                    ? "badge-danger"
                                                    : "badge-success"
                                            }`}
                                            title="Accountstatus"
                                        />
                                    </td>
                                    <td>
                                        {user.first_name} {user.last_name}
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.position_company}</td>
                                    <td>{user.company}</td>
                                    {isAdmin && (
                                        <td>
                                            <input type="checkbox" checked={user.isAdmin} readOnly={true}/>
                                        </td>
                                    )}
                                    <td>
                                        {isAdmin && (
                                            <ul className="project__edit">
                                                <li onClick={() => handleEditRow(index)}>
                                                    <FaEdit title="bearbeiten"/>
                                                </li>

                                                {/*{isAdmin &&*/}
                                                {/*(user.inactive !== true ? (*/}
                                                {/*    // <li*/}
                                                {/*    //   className="deactivate"*/}
                                                {/*    //   onClick={() => toggleUser(user.id, true)}*/}
                                                {/*    // >*/}
                                                {/*    //   <FaTimesCircle title="User deaktivieren" />*/}
                                                {/*    // </li>*/}
                                                {/*    <li*/}
                                                {/*        className="deactivate"*/}
                                                {/*        onClick={() => handleToggleUser(user, true)}*/}
                                                {/*    >*/}
                                                {/*        <FaTimesCircle title="User deaktivieren"/>*/}
                                                {/*    </li>*/}
                                                {/*) : (*/}
                                                {/*    <li*/}
                                                {/*        className="activate"*/}
                                                {/*        onClick={() => handleToggleUser(user, false)}*/}
                                                {/*    >*/}
                                                {/*        <FaCheckCircle title="User aktivieren"/>*/}
                                                {/*    </li>*/}
                                                {/*))}*/}
                                            </ul>
                                        )}
                                    </td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Users;
