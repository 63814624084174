import React from "react";
import "../../../css/Header.css";
import { useSelector, useDispatch } from "react-redux";
import { GoSignOut } from "react-icons/go";
import { FaBars, FaUserEdit } from "react-icons/fa";
import keycloak from "../../../adapters/keycloak/keycloak";
import {
  DelUserData,
  UserLoggedOut,
  ShowSidebar,
  HideSidebar,
} from "../../../adapters/redux/actions";
import { Link } from "react-router-dom";
import ArgusLogo from "../../../assets/img/argus_logo.png";
import ArgusLogoSmall from "../../../assets/img/argus_mini.png";
function Header() {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(DelUserData());
    dispatch(UserLoggedOut());
    keycloak.logout();
  };

  const showSidebar = useSelector((state) => state.showSidebar);
  const userData = useSelector((state) => state.userData);

  function toggleSidebar() {
    if (showSidebar) {
      dispatch(HideSidebar());
    } else {
      dispatch(ShowSidebar());
    }
  }

  return (
    <header>
      <div className={`header__logo ${showSidebar && `header__logoSmall`}`}>
        {showSidebar ? (
          <img src={ArgusLogoSmall} alt="logo" />
        ) : (
          <img src={ArgusLogo} alt="logo" />
        )}
      </div>
      <div className="header__topbar">
        <div className="header__left">
          <ul className="user__menu">
            <li className="activate" onClick={() => toggleSidebar()}>
              <FaBars />
            </li>
          </ul>
        </div>
        <div className="header__right">
          <div className="user__info">
            <div className="user__name">
              {userData.first_name} {userData.last_name}
            </div>
            <div className="user__position">{userData.company}</div>
            {/* <div className="user__position">{userData.position}</div> */}
          </div>
          <div className="menu__divider"></div>
          <ul className="user__menu">
            <Link to={`/benutzer/bearbeiten/`}>
              <li>
                <FaUserEdit title="Benutzerprofil bearbeiten" />
              </li>
            </Link>

            <li>
              <GoSignOut onClick={logout} title="Ausloggen" />
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
