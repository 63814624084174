const config = {
    //fetch_url: "https://argus.api.dmt-group.com/",
    fetch_url: "https://api.core.es.dmt-group.dev/",
    // fetch_url: "http://localhost:1963/",
    // fetchSicherEs_url: "http://localhost:1964/",
    fetchSicherEs_url: "https://api.sicher.es.dmt-group.dev/",
    fetchSicherEs_Liveurl: "https://api.sicher.es.dmt-group.dev/",
    application_id: '7e2f73d8-f636-48e8-9def-1ac785aafaf0',
    plRole: 'eff8af05-6bcc-4a9b-ab40-7a92301c740b',
    onlyOffice_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.LDkLNSFl15nGPXUs_DY-3_YIqAuDo5sE1T5P-vEpvUY'
};

export default config;
