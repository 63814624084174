export const SetUserData = (data) => {
  return {
    type: "SET_USER_DATA",
    payload: data,
  };
};
export const SetProjects = (data) => {
  return {
    type: "SET_PROJECT_DATA",
    payload: data,
  };
};
export const AddProjectData = (data) => {
  return {
    type: "ADD_PROJECT",
    payload: data,
  };
};

export const EditUserData = (data) => {
  return {
    type: "UPDATE_USER",
    payload: data,
  };
};
export const EditProjectData = (data) => {
  return {
    type: "UPDATE_PROJECT",
    payload: data,
  };
};

export const DeactivateProject = (id) => {
  return {
    type: "DEACTIVATE_PROJECT",
    payload: id,
  };
};

export const ActivateProject = (id) => {
  return {
    type: "ACTIVATE_PROJECT",
    payload: id,
  };
};

export const UpdateDisabledProjects = (data) => {
  return {
    type: "UPDATE_INACTIVE_PROJECT",
    payload: data,
  };
};

export const DelUserData = () => {
  return {
    type: "DEL_USER_DATA",
  };
};

export const SetSelectedPage = (data) => {
  return {
    type: "SET_PAGE_SELECTION",
    payload: data,
  };
};

export const SetPreloadDone = () => {
  return {
    type: "SET_PRELOAD_DONE",
  };
};

export const UserLoggedIn = () => {
  return {
    type: "USER_LOGGED_IN",
  };
};

export const UserLoggedOut = () => {
  return {
    type: "USER_LOGGED_OUT",
  };
};

export const OpenSnackbar = (data, color = true) => {
  return {
    type: "SNACKBAR_OPEN",
    payload: data,
    // true -> gruen // false -> rot
    color: color,
  };
};

export const CloseSnackbar = () => {
  return {
    type: "SNACKBAR_CLOSE",
  };
};

export const ShowSidebar = () => {
  return {
    type: "SHOW_SIDEBAR",
  };
};

export const HideSidebar = () => {
  return {
    type: "HIDE_SIDEBAR",
  };
};
