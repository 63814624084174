import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./adapters/redux/store";
import ScrollMemory from "react-router-scroll-memory";

ReactDOM.render(
  <Provider store={store}>
    {/* <BrowserRouter basename="/argusum"> */}
    <BrowserRouter>
      <ScrollMemory />
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
