import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    SetSelectedPage
} from "../../../adapters/redux/actions";
import UserDataTable from "./components/UserDataTable";
import BaseInformations from "./components/BaseInformations";
import ProjectDataTable from "./components/ProjectDataTable";
import ContactDataTable from "./components/ContactDataTable";
import {Button} from "primereact/button";
import ExportDialog from "./components/ExportDialog";
import functions from "../../../assets/DBCalls/functions";
import generalFunctions from "../../../assets/General/generalFunctions"
import {useParams} from "react-router-dom";
import StopkartenDataTable from "./components/StopkartenDataTable";
import LmraDataTable from "./components/LmraDataTable";
import GbuDataTable from "./components/GbuDataTable";
import { TabView, TabPanel } from 'primereact/tabview';

function Project(props) {
    // const {slug} = props.match.params;
    const [dataProject, setProjectData] = useState(null);
    const [gbuData, setGbuData] = useState(null);
    const dispatch = useDispatch();

    const isAdmin = useSelector((state) => state.userData.admin);
   // console.log(isAdmin)
    const [dialogDisplay, setDialogDisplay] = useState('initial');
    const [toogleVisibl, setToogleVisible] = useState(false);
    const {slug} = useParams();

    const fetchSignedGbu = async () => {
        let url = 'project/' + slug + '/gbuInfo';
        return await functions.fetch(url, 'sicherEs');
    }

    const exportData = () => {
        setDialogDisplay('change');
        setToogleVisible(!toogleVisibl);//Um change zu triggern, sonst nicht gebraucht
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        dispatch(
            SetSelectedPage({
                title: "Projektdetails",
                breadcrump: "Projekt / Projektdetails",
                id: "project_details",
            })
        );
        let gbuCall = await fetchSignedGbu();
        if (gbuCall) {
            setGbuData(gbuCall)
        }
        let data = await functions.fetch('project/' + slug);
        if (data) {
            setProjectData(data);
        }

    }, []);


    if (!dataProject) return <div>Kein gültiges Project gefunden</div>;

    return (
        <div>
            <BaseInformations title={dataProject.name} id={slug}
                              inactive={dataProject.is_inactive} role={dataProject}
                              isAdmin={isAdmin} number={dataProject.alibi} page={'show'}/>
            <div className="card">
                <div className="card__header">Benutzer</div>
                <div className="card__body">
                    <UserDataTable id={slug} page={'show'} gbuData={gbuData}/>
                </div>
            </div>
            <div className="card">
                <div className="card__header">Unterprojekte</div>
                <div className="card__body">
                    <ProjectDataTable subProjects={dataProject.subprojects} gbuData={gbuData} page={'show'}/>
                </div>
            </div>
            <div className="card">
                <div className="card__header">Kontakte</div>
                <div className="card__body">
                    <ContactDataTable id={slug} page={'show'}/>
                </div>
            </div>
            <div className="card">
                <div className="card__header">Gefährdungsbeurteilungen</div>
                <div className="card__body">
                    <GbuDataTable id={slug} page={'show'}/>
                </div>
            </div>
            <div className="card">
                <div className="card__header">Erfasste Stopkarten</div>
                <div className="card__body">
                    <StopkartenDataTable id={slug} page={'show'}/>
                </div>
            </div>
            <div className="card">
                <div className="card__header">Erfasste Last Minute Risk Analyse (LMRA)</div>
                <div className="card__body">
                    <LmraDataTable id={slug}/>
                </div>
            </div>
            <p><Button label="Unterlagen exportieren" className="p-button-outlined" aria-label="exportieren"
                       onClick={() => exportData()}/></p>
            <ExportDialog buttonaction={dialogDisplay} toogleVisible={toogleVisibl} projectId={dataProject.id}
                          projectName={dataProject.name}/>
        </div>
    );
}

export default Project;
