import React, {useEffect, useState} from "react";
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import functions from "../../../../assets/DBCalls/functions";
import {ProgressSpinner} from 'primereact/progressspinner';
import '../../../../css/Exporter.css';

function ExportDialog(props) {
    const [display, setDisplay] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [spinnerDisplay, setSpinnerDisplay] = useState(false);
    const [buttonDisplay, setButtonDisplay] = useState(true);

    const renderFooter = () => {
        return (
            <div>
                {/*<Button label="abbrechen" icon="pi pi-times" onClick={() => onHide()} className="p-button-text"/>*/}
                <Button label="Download Dateien" disabled={buttonDisplay} icon="pi pi-check" onClick={() => onExport()} autoFocus/>
            </div>
        );
    }
    const onHide = () => {
        setDisplay(false)
    }

    const onExport = () => {
        // setDisplay(false);
        setSpinnerDisplay('block');
        onHide();
        functions.fetch('project/' + props.projectId + '/getAllFiles?from=' + startDate.toDateString() + ' 00:00:00&to=' + endDate.toDateString() + ' 23:59:59', 'sicherEs', true)
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    props.projectName + '.zip',
                );

                document.body.appendChild(link);
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                setSpinnerDisplay(false);
            });
    }

    useEffect(() => {
        if (props.buttonaction == 'change') {
            setDisplay(true);
        }

    }, [props])

    useEffect(()=>{
       // console.log(startDate, endDate)
        if(startDate!==null&&endDate!==null){
            setButtonDisplay(false)
        }
    },[startDate, endDate])

    return (<div>{spinnerDisplay ? <div className={'spinnerDiv'}>
        <div className={'spinnerInner'}><ProgressSpinner/></div>
    </div> : null}<Dialog header="Export" visible={display} style={{width: '50vw'}} footer={renderFooter()}
                          onHide={() => onHide()}>
        <div className="p-grid ">
            <div className="col p-md-6 p-lg-3">
                <div>Von</div>
                <Calendar id="exportStart" className="w-full" value={startDate} onChange={(e) => setStartDate(e.value)}
                          showIcon
                          dateFormat="dd.mm.yy"/>
            </div>

            <div className="col">
                <div>Bis</div>
                <Calendar id="exportEnd" className="w-full" value={endDate} onChange={(e) => setEndDate(e.value)}
                          showIcon
                          dateFormat="dd.mm.yy"/>
            </div>
        </div>
    </Dialog></div>)
}

export default ExportDialog;
