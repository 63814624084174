import React, {useEffect, useRef, useState} from "react";
import functions from "../../../../assets/DBCalls/functions";
import {Accordion, AccordionTab} from 'primereact/accordion';
import UserDataTable from "./UserDataTable";
import {FaCheckCircle, FaTimesCircle} from "react-icons/fa";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from 'primereact/inputtext';
import config from "../../../../adapters/url_config/config";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {UpdateDisabledProjects} from "../../../../adapters/redux/actions";
import {useSelector} from "react-redux";

function LmraDataTable(props) {
    const [lmra, setLmra] = useState([]);
    const [projectStatusInactive, setProjectStatusInactive] = useState([]);
    const inactiveProjects = useSelector((state) => state.userData.inactiveProjects);

    const fetchLmra = async (id) => {
        let url = 'project/' + id + '/projectwithlmra';
        let data = await functions.fetch(url, 'sicherEs');
       // console.log(data)
        setLmra(data)
    };

    const createAndDownloadPdf = async (id) => {
        await functions.fetch('project/' + props.id + '/lmra/pdf/' + id, 'sicherEs').then(res => {

            const linkSource = `data:application/pdf;base64,` + res;
            const downloadLink = document.createElement("a");
            const fileName = "Lmra.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        })
    }

    const lmraErfasstTemplate = (rowData) => {
        return (
            <React.Fragment>
                Last Minute Risk Analyse (LMRA) erfasst
            </React.Fragment>
        );
    }

    const pdfTemplate = (rowData) => {

        return (
            <React.Fragment>
                <Button icon="pi pi-file-pdf"
                        className="p-button-rounded p-button-secondary p-button-text p-0"
                        aria-label="pdf" onClick={() => {
                    createAndDownloadPdf(rowData.id)
                }}/>
            </React.Fragment>
        );
    }

    const lmraNameTemplate = (rowData) => {

        let dateDB = rowData.created_at;

        let date = new Date(dateDB);
       // let userTimezoneOffset = date.getTimezoneOffset() * 60000;
       // date=new Date(date.getTime() + userTimezoneOffset);
        return (
            <React.Fragment>
                {date.toLocaleDateString('de-DE')} {date.toLocaleTimeString('de-DE')}
            </React.Fragment>
        );
    }


    const createdByTemplate = (rowData) => {

        return (
            <React.Fragment>
                {rowData.created_by_user.last_name}, {rowData.created_by_user.first_name}
            </React.Fragment>
        );
    }

    const mapAccordion = (project) => {
        let badge = inactiveProjects[project.id]
            ? " badge-danger"
            : " badge-success";
        let badgeClass = 'user__indicator flex m-auto mr-1' + badge;
        let badgeDiv = <div className={badgeClass}/>;
        let baseInformations = <div className='flex'>{badgeDiv}
            <div className='flex'>{project.name}</div>
        </div>;
        let header = <div className="project__edit">{baseInformations}</div>

        return <AccordionTab header={header} key={project.id}>
            <DataTable value={project.lmras} responsiveLayout="scroll">
                <Column field="lmra" header="" body={lmraErfasstTemplate}></Column>
                <Column field="file" header="" body={pdfTemplate}></Column>
                <Column field="created_at" header="Datum" body={lmraNameTemplate}></Column>
                <Column field="createdBy" header="Erfasser" body={createdByTemplate}></Column>
                <Column field="action_recommendation" header="Ergebnis"></Column>
            </DataTable></AccordionTab>
    }


    useEffect(() => {
        let inactiveArray = [];
        for (var project of lmra) {
            inactiveArray[project.id] = project.is_inactive;
        }
        setProjectStatusInactive(inactiveArray);

    }, [lmra]);


    useEffect(async () => {
        fetchLmra(props.id);
    }, []);

    return (<div><Accordion multiple>{lmra.map(project => {
        return mapAccordion(project);
    })}</Accordion></div>);

}

export default LmraDataTable;
