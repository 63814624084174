import "../css/App.css";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Sidebar from "./layout/Sidebar";
import Routing from "./layout/Routing";
import Preloading from "../utility/Preloading";
import {useSelector} from "react-redux";

import Loading from "../utility/loading";
import Snackbar from "./layout/Snackbar";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

//port 1841 , 1962

function App() {
    const isLoggedIn = useSelector((state) => state.isLoggedIn);
    const preloadDone = useSelector((state) => state.preloadDone);

    return (
        <div className="App">
            <Preloading/>

            {!preloadDone && <Loading/>}
            {preloadDone && !isLoggedIn && <div></div>}

            {isLoggedIn && preloadDone && (
                <>
                    <Header/>
                    <Snackbar/>
                    <Sidebar/>
                    <Routing/>
                    <Footer/>
                </>
            )}
        </div>
    );
}

export default App;
