import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SetSelectedPage } from "../../../adapters/redux/actions";

function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      SetSelectedPage({
        title: "Dashboard - Argus User Management",
        breadcrump: "Allgemein / Dashboard",
        id: "dashboard",
      })
    );
  }, []);

  return (
    <>
      <div>Willkommen auf der Verwaltungsoberfläche von Argus,</div>
      <br></br>
      <div>diese Seite soll später als Dashboard verwendet werden. </div>
    </>
  );
}

export default Home;
