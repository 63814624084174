import React, {useEffect, useState, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    SetSelectedPage
} from "../../../adapters/redux/actions";
import {Link, useHistory, useParams} from "react-router-dom";
import functions from "../../../assets/DBCalls/functions";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Checkbox} from 'primereact/checkbox';
import {Button} from "primereact/button";
import {Dialog} from 'primereact/dialog';

function SignGbu() {
    const dispatch = useDispatch();
    const {slug} = useParams();
    const history = useHistory();
    const [gbu, setGbu] = useState([]);
    const [gbuId, setGbuId] = useState([]);
    const [gbuTableData, setGbuTableData] = useState([]);
    const [gbuDgnData, setGbuDgnTableData] = useState([]);
    const [checked, setChecked] = useState(false);
    // const [nameUser, setNameUser] = useState('Name nicht gesetzt');
    const [project, setProject] = useState('Projekt nicht gesetzt');
    const userData = useSelector((state) => state.userData);
    const [displayDialog, setDialogDisplay] = useState(false);
    const [signedByMe, setSignedByMe] = useState(false);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const getProject = async () => {
        await functions.fetch('project/' + slug).then(async res => {

            if (res.parent_project_id) {
                await functions.fetch('project/' + res.parent_project_id).then(
                    res1 => {
                        setProject(res1.name + '-' + res.name);
                    }
                )
            } else {
                setProject(res.name);
            }
        })
    }
    const fetchSignedGbu = async () => {
        let url = 'project/' + slug + '/gbuInfo';
        let data = await functions.fetch(url, 'sicherEs');
        let gbuOfThisProject = [];
        if (data) {
            gbuOfThisProject = data.find(value => value.project_id === slug);
        }

        let signedData = [];
        if (gbuOfThisProject) {
            signedData = gbuOfThisProject.signed;
            let signedByUser = signedData.find(sign => sign.user.id === userData.id);
            setSignedByMe(signedByUser);
        }

    }

    const fetchLastGbu = async () => {
        const gbu = await functions.fetch('project/' + slug + '/gbu/last', 'sicherEs');
        setGbuId(gbu.id);
        await functions.fetch('project/' + slug + '/gbu/pdf/' + gbu.id, 'sicherEs').then(res => {

            const linkSource = `data:application/pdf;base64,` + res + '#zoom=100';
            setGbu(linkSource);
        })
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="OK" icon="pi pi-check" onClick={() => {
                    setDialogDisplay(false);
                    window.location.reload();
                }} autoFocus/>
            </div>
        );
    }


    const signGnu = async () => {

        await functions.post('project/' + slug + '/gbu/' + gbuId + '/sign', {}, 'sicherEs').then(res => {
            if (res) {
                setDialogDisplay(true)
            }
        })
    }

    useEffect(async () => {
        window.onresize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        dispatch(
            SetSelectedPage({
                title: "Gefährdungsbeurteilung (GBU) signieren",
                breadcrump: "Projekt / Gefährdungsbeurteilung (GBU) / signieren",
                id: "sign_gbu",
            })
        );
        await getProject();
        await fetchSignedGbu();
        await fetchLastGbu();
    }, []);

    if (signedByMe) return <div>Sie haben diese Gefährdungsbeurteilung (GBU) bereits unterzeichnet</div>;
    return (<div>
            <Dialog header="Speicherung erfolgreich" visible={displayDialog} style={{width: '50vw'}}
                    onHide={() => {
                        setDialogDisplay(false);
                    }} footer={renderFooter}>
                <p> Sie haben diese Gefährdungsbeurteilung (GBU) unterzeichnet.</p>
            </Dialog>
            <div className="card">
                <iframe src={gbu} height={windowSize.height * 0.85} width='100%'></iframe>
            </div>

            <div className="card">
                <div className="card__body">
                    <Checkbox inputId="acknowlegde" checked={checked} onChange={e => setChecked(e.checked)}/>
                    <label htmlFor="acknowlegde"> Hiermit bestätige
                        ich,<b> {userData.first_name} {userData.last_name}</b>, die für das
                        Projekt <b>{project}</b> hinterlegte Gefährdungsbeurteilung (GBU) gelesen und verstanden zu haben. Die
                        dargestellten Gefährdungen und jeweiligen Handlungsmassnahmen sind verstanden worden.</label>

                </div>
            </div>
            <Button label="Gefährdungsbeurteilung (GBU) abzeptieren" disabled={!checked}
                    onClick={signGnu}/>
        </div>
    )
}

export default SignGbu;