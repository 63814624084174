import React, {useEffect, useRef, useState} from "react";
import functions from "../../../../assets/DBCalls/functions";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Link} from "react-router-dom";
import {FaCheckCircle, FaEdit, FaTimesCircle, FaTrashAlt} from "react-icons/fa";
import {Dropdown} from 'primereact/dropdown';
import valueMapping from "../../../../assets/General/valueMapping";
import {Button} from 'primereact/button';
import {OverlayPanel} from 'primereact/overlaypanel';
import config from '../../../../adapters/url_config/config';
import {InputText} from 'primereact/inputtext';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {OpenSnackbar} from "../../../../adapters/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from 'primereact/toast';

function UserDataTable(props) {
    const [dataUsers, setDataUsers] = useState([]);
    const [optionValue, setOptionValue] = useState([]);
    const [rolesToUpdate, setRolesToUpdate] = useState([]);
    const [disabledBttn, setDisabledBttn] = useState(true);
    const [userIsInactive, setUserIsInactive] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [choosenUser, setChoosenUser] = useState('');
    const [choosenUserRole, setChoosenUserRole] = useState('');
    const [choosenApp, setChoosenApp] = useState('7e2f73d8-f636-48e8-9def-1ac785aafaf0');
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [applicationRows, setApplicationRows] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [signedGbuData, setSignedGbuData] = useState([]);
    const dispatch = useDispatch();
    const op = useRef(null);
    const isMounted = useRef(false);
    const toast = useRef(null);

    const [filtersUser, setFiltersUser] = useState({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'combinedName': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'email': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'company': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    });

    const iterateThrougUserData = async (data, signedData) => {
        let inactiveArray = [];
        let newData=[];
        if (data.length > 0) {
            for (let elem of data) {
                let signedByUser = await signedData.find(sign => sign.user.id === elem.id);
                inactiveArray[elem.id] = elem.is_inactive;
                if (elem.last_name) {
                    elem.combinedName = elem.last_name + ', ' + elem.first_name;
                }
                if (signedByUser) {
                    elem.signed = true;
                }
                newData.push(elem);
            }
        }
        setUserIsInactive(inactiveArray);
        return newData;

    }

    const fetchSignedGbu = async () => {
        let url = 'project/' + props.id + '/gbuInfo';
        return await functions.fetch(url, 'sicherEs');
    }

    const fetchDataUsers = async (id) => {

        let url = 'project/' + id + '/user?allUser=true';
        let data = await functions.fetch(url);
     //   let inactiveArray = [];
        let gbuOfThisProject;
      //  console.log(props);
        let gbuData=props.gbuData;
        if(!props.gbuData){
            gbuData = await fetchSignedGbu();
        }
        if (gbuData) {
            gbuOfThisProject = await gbuData.find(value => value.project_id === props.id);
            //console.log(gbuOfThisProject)
        }

        let signedData = [];
        if (gbuOfThisProject) {
            signedData = gbuOfThisProject.signed;
        }

        let signedUserData = await iterateThrougUserData(data, signedData);
//console.log(signedUserData)

        setDataUsers(signedUserData);

    };


    const generateAppColumns = (disabled) => {
        return applicationRows.map(row => {
            let fieldName = "roleEdit_" + row.name;
            return <Column field={fieldName} header={row.name} idApp={row.id} disabledInfo={disabled}
                           body={rolesBodyTemplate}/>
        })
    }


    const toggleUser = async (userID, status) => {
        if (userID) {
            await functions.put('project/' + props.id + '/user/' + userID + '/setactive', JSON.stringify({value: !status})).then(res => {

                if (res) {
                    setUserIsInactive(prevState => ({...prevState, [userID]: status}))
                }
            })
        }
    };

    const removeUser = async (userID) => {
        if (userID) {
            await functions.delete('project/' + props.id + '/users', [{user_id: userID}]).then(async res => {
                if (res) {
                    await callBaseData();
                }
            })
        }
    };


    const callBaseData=async()=>{
        let user = await valueMapping.user({projectId: props.id});
        let roles = await valueMapping.role();
        let apps = await valueMapping.application();

        await fetchDataUsers(props.id);
        isMounted.current = true;

        setApplicationRows(apps);
        setUserOptions(user);

        setRoleOptions(roles);
    }

    const testIfUserIsAdmin=(data, id)=>{
        let user=data.find(user=>user.id==id);
        return user.isAdmin;
    }

    const addUserToProject = (data) => {
        let adminRole=testIfUserIsAdmin(userOptions, choosenUser);
        let role=choosenUserRole;
        if(adminRole){
            role=config.plRole;
            if(adminRole){
                 toast.current.show({severity:'info', summary: 'Bitte beachten', detail:'Dieser User ist ein Admin. Er wird als Projektmanager hinzugefügt.', life: 3000});
            }
        }
        functions.post('project/' + props.id + '/user/' + choosenUser, {
            application_id: choosenApp,
            role_id: role
        }).then(async res => {
            if (res) {
                await callBaseData();
                op.current.toggle(false)
            } else {
                alert(res.error)
            }
        })
    }

    const rolesBodyTemplate = (rowData, column) => {

        let appId = column.column.props.idApp;
        let disabledInfo = column.column.props.disabledInfo;
        if(!optionValue[rowData.id]||!optionValue[rowData.id][appId]){
            return '';
        }

        return (
            <React.Fragment>

                <Dropdown value={optionValue[rowData.id][appId]} options={roleOptions} optionValue={'id'}
                          optionLabel={'name'} disabled={disabledInfo}
                          onChange={(e) => setDropDownValues(rowData.id, e.value, appId)}
                          placeholder="Keine Auswahl"/>
            </React.Fragment>
        );
    }

    const saveRoleChanges = () => {
        let bodyArray = [];
        let deleteArray = [];
        for (var i in rolesToUpdate) {
            let roles = rolesToUpdate[i];
            for (let r in roles) {
                if (roles[r]) {
                    bodyArray.push({user_keycloak_id: i, application_id: r, role_id: roles[r]});
                } else {
                    deleteArray.push({user_id: i, application_id: r});
                }

            }

        }
        let counter = 0;
        let totalLenght = 2;
        if (bodyArray.length > 0) {
            functions.put('project/' + props.id + '/user/', JSON.stringify({value: bodyArray})).then(res => {
                if (res) {
                    counter++;
                } else {
                    alert(res)
                }
            });
        } else {
            counter++;
        }


        if (deleteArray.length > 0) {

            functions.delete('project/' + props.id + '/users', deleteArray).then(res => {
                if (res) {
                    counter++;
                }
            });
        } else {
            counter++;
        }
       let interval= setInterval(async function () {

            //console.log(counter, totalLenght)
            if (counter == totalLenght) {
                //    window.location.reload()
                await reloadUser();
                clearInterval(interval);

            }
        }, 1000)
    }


    const setDropDownValues = (id, value, appId) => {
        let isAdmin= testIfUserIsAdmin(dataUsers, id);
        if(isAdmin&&value!==config.plRole){
            return toast.current.show({severity:'error', summary: 'Fehler', detail:'Dieser User ist ein Admin. Role kann nicht verändert werden', life: 3000});
        }
        let optionsVals = optionValue;
        optionsVals[id][appId] = value;
        setOptionValue(optionsVals);
        setRolesToUpdate(prevState => ({...prevState, [id]: {...prevState[id], [appId]: value}}));
        setDisabledBttn(false);
    }

    const deactivateUserTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="project__edit"> {userIsInactive[rowData.id] == true ? (
                    <li className="activate" onClick={() => toggleUser(rowData.id, false)}><FaCheckCircle/></li>
                ) : (
                    <li className="deactivate" onClick={() => toggleUser(rowData.id, true)}><FaTimesCircle/></li>
                )}</div>
            </React.Fragment>
        );
    }

    const deleteUserTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="project__edit">
                    <li className="activate" onClick={() => removeUser(rowData.id)}><FaTrashAlt color='#666666'/></li>
                </div>
            </React.Fragment>
        );
    }

   /* const badgeBodyTemplate = (rowData) => {
        let badge = userIsInactive[rowData.id]
            ? " badge-danger"
            : " badge-success";

        return (
            <React.Fragment>
                <div className={'user__indicator' + badge}/>
            </React.Fragment>
        );
    }*/

    const signedTemplate = (rowData) => {

        return (
            <React.Fragment>
                <div className="project__edit"> {rowData.signed == true ? (
                    /* <Link to={'/gbu/sign/' + props.id}>*/
                    <FaEdit title="Gefährdungsbeurteilung (GBU) bereits unterzeichnet"/>
                    /* </Link>*/
                ) : ''}</div>
            </React.Fragment>
        );
    }

    const reloadUser = async () => {
        await fetchDataUsers(props.id);
        dispatch(OpenSnackbar("Änderungen wurden übernommen.", true));
    }

    const onGlobalFilterChange = (e) => {

        const value = e.target.value;
        let _filtersUser = {...filtersUser};
        _filtersUser['global'].value = value;

        setFiltersUser(_filtersUser);
        setGlobalFilterValue(value);
    }

    const renderHeaderUser = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search"/>
                </span>
            </div>
        )
    }

    const headerUser = renderHeaderUser();

    useEffect(() => {
        if (choosenUserRole !== '' && choosenUser !== '' && choosenApp !== '') {
            setDisabledSubmit(false)
        } else {
            setDisabledSubmit(true)
        }
    }, [choosenUserRole, choosenUser, choosenApp])


    useEffect(() => {
        let userRoleValue = [];
        let saveBttnArray = [];

        for (const user of dataUsers) {
            for (const i in user.roleArray) {
                const role = user.roleArray[i];
                if (!userRoleValue[user.id]) userRoleValue[user.id] = [];
                userRoleValue[user.id][role.app_id] = role.role_id;
            }
            saveBttnArray[user.id] = false;
        }
        setOptionValue(userRoleValue);
    }, [dataUsers]);

    useEffect(async () => {
        await callBaseData();
    }, []);

    return (<div><Toast ref={toast} />
        <DataTable className="card__table hovereffect" value={dataUsers} responsiveLayout="scroll"
                            header={headerUser}
                            sortField="combinedName" sortOrder={1} filters={filtersUser}
                            globalFilterFields={['combinedName', 'email',]} filterDisplay="row">
        <Column field="signed" header="" body={signedTemplate}/>
        <Column field="combinedName" filterField="combinedName" header="Name"/>
        <Column field="email" header="Email" filterField="email"/>
        <Column field="phone" header="Tel."/>
        <Column field="position" header="Position"/>
        <Column field="company" header="Firma"/>
        {props.page == 'show' && generateAppColumns(true)}
        {props.page == 'edit' && generateAppColumns(false)}
        {props.page == 'edit' &&
        <Column field="deleteUser" header="" body={deleteUserTemplate}/>}
    </DataTable>

        {props.page == 'edit' &&
        <div><Button label="Änderungen speichern" aria-label="Änderungen speichern" disabled={disabledBttn}
                     onClick={saveRoleChanges}/>
            <Button type="button" icon="pi pi-user" label={'Mitarbeiter hinzufügen'}
                    onClick={(e) => op.current.toggle(e)}
                    aria-haspopup aria-controls="overlay_panel"/>

            <OverlayPanel ref={op} showCloseIcon id="overlay_panel" style={{width: '450px'}}
                          className="overlaypanel-demo">

                <div className="p-fluid">
                    <Dropdown value={choosenUser} options={userOptions} optionValue={'id'} required={true}
                              optionLabel={'fullName'}
                              onChange={(e) => setChoosenUser(e.value)} placeholder="Wählen Sie einen User"/><br/>
                    <Dropdown value={choosenUserRole} options={roleOptions} optionValue={'id'} required={true}
                              optionLabel={'name'}
                              onChange={(e) => setChoosenUserRole(e.value)} placeholder="Wählen Sie eine Rolle"/><br/>
                    <Button label="Hinzufügen" disabled={disabledSubmit} onClick={addUserToProject}/>
                </div>
            </OverlayPanel></div>}
    </div>)
}

export default UserDataTable;