import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {SetSelectedPage, OpenSnackbar} from "../../../adapters/redux/actions";
import {FaEdit, FaTimesCircle, FaCheckCircle} from "react-icons/fa";
import {RiDeleteBin6Line} from "react-icons/ri";
import config from "../../../adapters/url_config/config";
import Modal from "./Modal";
import functions from "../../../assets/DBCalls/functions";

function Contacts() {
    const token = localStorage.getItem("keycloak-token");
    const dispatch = useDispatch();
    const [contacts, setContacts] = useState([]);
    const [dataFiltered, setDataFiltered] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [editRow, setEditRow] = useState(null);

    const handleSearch = (input) => {
        const search = contacts.filter(
            (user) =>
                (user.name && user.name.toLowerCase().includes(input.toLowerCase())) ||
                (user.company && user.company.toLowerCase().includes(input.toLowerCase()))
        );
        setDataFiltered(search);
    };

    const fetchContact = () => {
        setIsLoading(true);
        setHasError(false);

        functions.fetch("contact")
            //.then((response) => response.json())
            .then((data) => {
                if (data) {
                    data.sort((a, b) => (a.name > b.name ? 1 : -1));
                    setContacts(data);
                    setDataFiltered(data);
                } else {
                    setHasError(true);
                }
                setIsLoading(false);
            });
    };
    const saveEditedRow = (contactId) => {
        const name = document.getElementById("edit_table_name").value;
        const mail = document.getElementById("edit_table_mail").value;
        const phone = document.getElementById("edit_table_phone").value;
        const position = document.getElementById("edit_table_position").value;
        const company = document.getElementById("edit_table_company").value;

        /*console.log(
            contactId,
            name,
            mail,
            phone,
            position,
            company
        );*/

        const body = JSON.stringify({
            name: name,
            mail: mail,
            phone: phone,
            position: position,
            company: company,
        });

        functions.put(`contact/` + contactId, body)
            .then((success) => {
                if (success) {
                    setEditRow(null);
                    setDataFiltered(
                        dataFiltered.map((contact) =>
                            contact.id === contactId
                                ? {
                                    ...contact,
                                    name: name,
                                    mail: mail,
                                    phone: phone,
                                    position: position,
                                    company: company,
                                }
                                : contact
                        )
                    );
                    setContacts(
                        contacts.map((contact) =>
                            contact.id === contactId
                                ? {
                                    ...contact,
                                    name: name,
                                    mail: mail,
                                    phone: phone,
                                    position: position,
                                    company: company,
                                }
                                : contact
                        )
                    );
                }
            });
    };

    const handleEditRow = (row) => {
        setEditRow(row);
    };

    const deleteContact = (contactID) => {
        closeModal();
        setSelectedContact(null);

        const requestOptions = {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                options: {
                    where: {
                        contact_id: contactID,
                    },
                },
            }),
        };
        fetch(config.fetch_url + 'contact/' + contactID + '/delete', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.deleted) {
                    dispatch(OpenSnackbar("Kontakt gelöscht.", true));
                    setDataFiltered(
                        dataFiltered.filter((contact) => contact.id !== contactID)
                    );
                    setContacts(
                        contacts.filter((contact) => contact.id !== contactID)
                    );
                } else {
                    dispatch(OpenSnackbar(data.error ? data.error : data, true));
                }
            });
    };

    const handleDeleteContact = (contact) => {
        const requestOptions = {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };

        fetch(
            config.fetch_url +
            'contact/' + contact.id + '/getprojects',
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                setSelectedContact({
                    data: contact,
                    projects: data,
                });
                document.body.style.overflow = "hidden";
                setOpenModal(true);
            });
    };

    const closeModal = () => {
        document.body.style.overflow = "";
        setOpenModal(false);
    };

    useEffect(() => {
        dispatch(
            SetSelectedPage({
                title: "Kontakte",
                breadcrump: "Kontakte / Übersicht",
                id: "contacts",
            })
        );
        fetchContact();
    }, []);

    if (isLoading) return <p>Lade Kontaktliste...</p>;
    if (hasError)
        return (
            <p>
                Es ist ein Fehler aufgetretet. Bitte wenden Sie sich an einen
                Administrator.
            </p>
        );

    //console.log(contacts);

    return (
        <>
            {openModal && selectedContact && (
                <Modal
                    close={closeModal}
                    buttonFunc={deleteContact}
                    contact={selectedContact}
                />
            )}

            <div className="card">
                <div className="card__header">
                    <input
                        autoComplete="off"
                        type="text"
                        id="contact-management-search"
                        placeholder="Suche einen Kontakt..."
                        onChange={(e) => handleSearch(e.target.value)}
                        className="card__userSearch form-control"
                    />
                </div>
                <div className="card__body">
                    <table className="card__table hovereffect">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Tel.</th>
                            <th>Position</th>
                            <th>Firma</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {dataFiltered.map((contact, index) =>
                            editRow === index ? (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type="text"
                                            id="edit_table_name"
                                            defaultValue={contact.name}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id="edit_table_mail"
                                            defaultValue={contact.email}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id="edit_table_phone"
                                            defaultValue={contact.phone}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id="edit_table_position"
                                            defaultValue={contact.position}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            id="edit_table_company"
                                            defaultValue={contact.company}
                                        />
                                    </td>
                                    <td>
                                        <ul className="project__edit">
                                            <li
                                                onClick={() => saveEditedRow(contact.id)}
                                                className="activate"
                                            >
                                                <FaCheckCircle/>
                                            </li>
                                            <li
                                                onClick={() => setEditRow(null)}
                                                className="deactivate"
                                            >
                                                <FaTimesCircle/>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            ) : (
                                <tr key={contact.id}>
                                    <td>
                                        {contact.name}
                                    </td>
                                    <td>{contact.email}</td>
                                    <td>{contact.phone}</td>
                                    <td>{contact.position}</td>
                                    <td>{contact.company}</td>
                                    <td>
                                        <ul className="project__edit">
                                            <li onClick={() => handleEditRow(index)}>
                                                <FaEdit title="bearbeiten"/>
                                            </li>

                                            <li onClick={() => handleDeleteContact(contact)}>
                                                <RiDeleteBin6Line/>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Contacts;
