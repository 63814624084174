import functions from "../DBCalls/functions";

const generalFunctions = {
    getRole: async (isAdmin, projectId) => {
        let role;
        if (isAdmin) {
            role = 'Admin'
        } else {
            let url = 'project/' + projectId + '/user?app=7e2f73d8-f636-48e8-9def-1ac785aafaf0&allUser=true&ownData=true';
            let data = await functions.fetch(url);
            if (data.length > 0)
                role = data[0].roleArray[0].role;
        }
        return role;
    },

    uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
}
export default generalFunctions;