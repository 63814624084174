import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SetSelectedPage, OpenSnackbar } from "../../../adapters/redux/actions";
import { useForm } from "react-hook-form";
import "../../../css/CreateContact.css";
import config from "../../../adapters/url_config/config";
import functions from "../../../assets/DBCalls/functions";

function CreateContact() {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data, e) => {
   // console.log(data);
    addContact(
      data.firstName,
      data.lastName,
      data.email,
      data.phone,
      data.company,
      data.position
    );
    reset();
  };

  useEffect(() => {
    dispatch(
      SetSelectedPage({
        title: "Kontakt anlegen",
        breadcrump: "Kontakt / Anlegen",
        id: "contact_create",
      })
    );
  }, []);

  const createRandomId = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16).toUpperCase();
      }
    );
  };

  function addContact(firstName, lastName, email, phone, company, position) {
    const id = createRandomId();

    const body = {
      id: id,
      name: firstName + ' ' + lastName,
      email: email,
      phone: phone,
      company: company,
      position: position,
    };



    functions.post("contact", body)
      .then((data) => {
        // console.log(data);
        if (data) dispatch(OpenSnackbar("Kontakt wurde hinzugefügt."));
      });
  }
  return (
    <>
      <div className="createContact__container">
        <form className="createContact__form" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="firstname">Vorname</label>
          <input
            type="text"
            autoComplete="off"
            className={`form-control ${errors.firstName && "is-invalid"}`}
            {...register("firstName", {
              maxLength: {
                value: 50,
                message: "Vorname zu lang",
              },

              required: "Vorname eingeben",
              pattern: {
                // value: /^[A-Za-z]+$/i,
                value: /^[a-zA-Z]+(?:[\s-]+[a-zA-Z]+)*$/i,
                message: "Nur Buchstaben erlaubt (keine Umlaute).",
              },
            })}
          />
          {errors.firstName && (
            <div className="error-feedback">{errors.firstName.message}</div>
          )}
          <label htmlFor="lastName">Nachname</label>
          <input
            type="text"
            autoComplete="off"
            className={`form-control ${errors.lastName && "is-invalid"}`}
            {...register("lastName", {
              maxLength: {
                value: 50,
                message: "Nachname zu lang",
              },
              required: "Nachname eingeben.",
              pattern: {
                // value: /^[A-Za-z]+$/i,
                value: /^[a-zA-Z]+(?:[\s-]+[a-zA-Z]+)*$/i,
                message: "Nur Buchstaben erlaubt (keine Umlaute).",
              },
            })}
          />
          {errors.lastName && (
            <div className="error-feedback">{errors.lastName.message}</div>
          )}
          <label htmlFor="email">E-Mail</label>
          <input
            type="text"
            autoComplete="off"
            className={`form-control ${errors.email && "is-invalid"}`}
            {...register("email", {
              maxLength: {
                value: 100,
                message: "E-Mail zu lang",
              },
              required: "E-Mail eingeben",
              pattern: {
                value:
                  /^[a-zA-Z0-9._-]+(?:\.[a-zA-Z0-9._-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
                ///^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Ungültige E-Mail-Adresse",
              },
            })}
          />
          {errors.email && (
            <div className="error-feedback">{errors.email.message}</div>
          )}
          <label htmlFor="phone">Telefonnummer</label>
          <input
            type="tel"
            autoComplete="off"
            className={`form-control ${errors.phone && "is-invalid"}`}
            {...register("phone", {
              maxLength: {
                value: 20,
                message: "Telefonnummer zu lang",
              },
              required: "Telefonnummer eingeben",
              pattern: {
                value:
                  /^([+](\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,10}$/im,
                message: "Ungültige Telefonnummer",
              },
            })}
          />
          {errors.phone && (
            <div className="error-feedback">{errors.phone.message}</div>
          )}
          <label htmlFor="company">Firmenname</label>
          <input
            type="text"
            autoComplete="off"
            className={`form-control ${errors.company && "is-invalid"}`}
            {...register("company", {
              maxLength: {
                value: 50,
                message: "Firmenname zu lang",
              },
              required: "Firmenname eingeben.",
            })}
          />
          {errors.company && (
            <div className="error-feedback">{errors.company.message}</div>
          )}
          <label htmlFor="position">Positionsbezeichnung</label>
          <input
            type="text"
            autoComplete="off"
            className={`form-control ${errors.position && "is-invalid"}`}
            {...register("position", {
              maxLength: {
                value: 100,
                message: "Positionsbezeichnung zu lang",
              },
              required: "Positionsbezeichnung eingeben.",
            })}
          />
          {errors.position && (
            <div className="error-feedback">{errors.position.message}</div>
          )}

          <input
            className="btn btn-primary"
            type="submit"
            value="Kontakt erstellen"
          />
        </form>
      </div>
    </>
  );
}

export default CreateContact;
