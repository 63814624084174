import React, {useEffect, useState, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    OpenSnackbar,
    SetSelectedPage
} from "../../../adapters/redux/actions";
import {Link, useHistory, useParams} from "react-router-dom";
import functions from "../../../assets/DBCalls/functions";
import {Button} from 'primereact/button';

import {InputTextarea} from "primereact/inputtextarea";

function StopcardInfo(props) {
    const dispatch = useDispatch();
    const {slug, project_id} = useParams();
    const history = useHistory();

    const [selectedStopcard, setSelectedStopcard] = useState(null);
    const [measuresTaken, setMeasuresTaken] = useState(null);
    const [projectName, setProjectName] = useState([]);
    const [subProjectName, setSubProjectName] = useState([]);

    const getProject = async () => {
        await functions.fetch('project/' + project_id).then(async res => {

            if (res.parent_project_id) {
                await functions.fetch('project/' + res.parent_project_id).then(
                    res1 => {
                        setProjectName(res1.name);
                        setSubProjectName(res.name);
                    }
                )
            } else {
                setProjectName(res.name);
            }
        })
    }

    const getStopcard = async () => {
        await functions.fetch('project/' + project_id + '/stopcard/' + slug, 'sicherEs').then(async result => {
            result = JSON.parse(JSON.stringify(result));
            setSelectedStopcard(result);
            setMeasuresTaken(result.measures_taken);
        })
    }

    const closeStopcard = async () => {
        let body = JSON.stringify({measures_taken: measuresTaken});

        await functions.put('project/' + project_id + '/stopcard/' + slug + '/close', body, 'sicherEs').then(result => {
            dispatch(OpenSnackbar("Stopkarte geschlossen!", true));
            history.goBack();
        });
    }

    useEffect(async () => {
        await getProject();
        await getStopcard();

        // dispatch(
        //     SetSelectedPage({
        //         title: "Gefährdungsbeurteilung (GBU) anlegen",
        //         breadcrump: "Projekt / GBU",
        //         id: "project_gbu",
        //     })
        // );
    }, []);


    return (<div>
            <div className="card">
                <div className="card__header"></div>
                <div className="card__body">
                    <div>
                        <div className="card">
                            <div className="card__header">Ausgewähltes Projekt</div>
                            <div className="card__body"><b>Projektname:</b> {projectName}
                                <b> Unterprojekt:</b> {subProjectName}</div>
                        </div>
                        {selectedStopcard &&
                        <div className="card">
                            <div
                                className="card__header">{"Stopkarte " + (new Date(selectedStopcard.created_at).toLocaleString())}</div>
                            <div className="card__body">
                                <InputTextarea style={{width: '100%'}} value={measuresTaken} autoResize
                                               placeholder="Getroffene Maßnahmen"
                                               onChange={(e) => {
                                                   setMeasuresTaken(e.target.value);
                                               }}></InputTextarea>
                            </div>

                            <div className="card">
                                <Button label="Stopkarte schließen" onClick={closeStopcard}/>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StopcardInfo;
