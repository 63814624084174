import functions from "../DBCalls/functions";

const valueMapping = {
    role: async () => {
        let roles = [];
        let data = await functions.fetch('getRole ');
        return data;
    },
    user: async (data) => {
        let addOn = '';
        if (data.projectId) {
            addOn += '?noprojectid=' + data.projectId;
        }
        let users = await functions.fetch('user'+addOn);
        for (const u of users) {
            u.fullName = u.last_name + ', ' + u.first_name;
        }
        return users;
    },
    application: async () => {
        //let data = await functions.fetch('getApplication');
        let data =[{"id":"7e2f73d8-f636-48e8-9def-1ac785aafaf0","name":"sicherES"}];
        return data;
    },
    workspace: async () => {
        let data = await functions.fetch('work_type', 'sicherEs');
        return data
    },
    contact: async () => {
        let data = await functions.fetch('contact');
        return data
    }
}
export default valueMapping;