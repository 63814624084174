import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    SetSelectedPage,
    DeactivateProject,
    ActivateProject, AddProjectData,
} from "../../../adapters/redux/actions";
import {FaEdit, FaTimesCircle, FaCheckCircle, FaRegTimesCircle} from "react-icons/fa";

import {Link, useHistory} from "react-router-dom";

import functions from "../../../assets/DBCalls/functions";

function Projects() {
    const dispatch = useDispatch();
    // const history = useHistory();
    // const [project, setProject] = useState('');
    const [projects, setProjects] = useState([]);
    const [ownProjects, setOwnProjects] = useState([]);
    const isAdmin = useSelector((state) => state.userData.admin)

    useEffect(() => {
        dispatch(
            SetSelectedPage({
                title: "Projektübersicht",
                breadcrump: "Projekte / Übersicht",
                id: "project_overview",
            })
        );
    }, []);


    /* const navigateToProject = (value) => {
        setProject(value);
        history.push("projekt/"+value)
    } */

    const formatRole = (role) => {

        if (isAdmin) {
            return "Admin";
        }
        switch (role) {
            case "Admin":
                return "Admin";
            case "Standard":
                return "Standard";
            case "Project Manager":
                return "Project Manager";
            default:
                return "Keine Rolle gesetzt";
        }
    };

    /* const toggleProj = (projectID, status) =>
    {
        if (projectID)
        {
            const requestOptions_Deployments = {
                inactive: status ? 1 : 0,
            };

            fetch(config.fetch_url + `update/Projects`, requestOptions_Deployments)
                .then((response) => response.json())
                .then((data) =>
                {
                    if (data)
                    {
                        if (status) dispatch(DeactivateProject(projectID));
                        else dispatch(ActivateProject(projectID));
                    }
                });
        }
    }; */

    const toggleProj = async (projId, state) => {
        const body = state;
        await functions.patch('project/' + projId, body);

        let op = ownProjects.slice();

        for (let pro of op) {

            if (pro.id === projId) {
                pro.is_inactive = state;
                break;
            }
        }

        setOwnProjects(op);
    };

    useEffect(async () => {
        let mainData = await functions.fetch('project?allProjects=true&withInactive=true&onlyParents=true');
        setProjects(mainData);


        await functions.fetch('project/?onlyParents=true&withInactive=true').then((r) => {
            setOwnProjects(r);
        });

    }, []);

    return (<div>
            <div className="card">
                <div className="card__header">Meine Projekte</div>
                <div className="card__body">
                    <ul className="card__list hovereffect">
                        {ownProjects?.map((project) => (
                            <li className="card__item" key={project.id}>
                                <div className="card__project">
                                    <div className="card__left">
                                        <div
                                            className={`project__indicator ${
                                                project.is_inactive
                                                    ? "badge-danger"
                                                    : "badge-success"
                                            }`}
                                            title="Projektstatus"
                                        />

                                        <div className="project__info">
                                            <div className="project__title">
                                                <Link to={`/projekt/${project.id}`}>
                                                    {`${project.name} `}
                                                </Link>
                                            </div>
                                            <div className="project_number">{`Projektnummer: ${project.alibi}`}</div>
                                        </div>
                                    </div>
                                    <div className="card__right">
                                        <ul className="project__edit">
                                            <div
                                                className="project__role badge badge-info m-auto mr-4"
                                                title="Projektrolle"
                                            >
                                                {formatRole(project.role_name)}
                                            </div>
                                            {project.role_name === "Admin" ||
                                            project.role_name === "Project Manager" ? (
                                                <Link
                                                    to={`/projekt/bearbeiten/${project.id}`}
                                                >
                                                    <li>
                                                        <FaEdit title="bearbeiten"/>
                                                    </li>
                                                </Link>
                                            ) : (
                                                ""
                                            )}
                                            {isAdmin || project.role_name === "Admin" || project.role_name === "Project Manager" ?
                                                project.is_inactive ? (
                                                    <li
                                                        className="activate"
                                                        onClick={() =>
                                                            toggleProj(project.id, false)
                                                        }
                                                    >
                                                        <FaCheckCircle title="Projekt aktivieren"/>
                                                    </li>
                                                ) : (
                                                    <li
                                                        className="deactivate"
                                                        onClick={() =>
                                                            toggleProj(project.id, true)
                                                        }
                                                    >
                                                        <FaTimesCircle title="Projekt deaktivieren"/>
                                                    </li>
                                                )
                                                : ""}
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            {isAdmin &&
                <div className="card">
                    <div className="card__header">Alle Projekte</div>
                    <div className="card__body">
                        <ul className="card__list hovereffect">
                            {projects?.map((project) => (
                                <li className="card__item" key={project.id}>
                                    <div className="card__project">
                                        <div className="card__left">
                                            <div
                                                className={`project__indicator ${
                                                    project.is_inactive ? "badge-danger" : "badge-success"
                                                }`}
                                            />

                                            <div className="project__info">
                                                <div className="project__title">
                                                    <Link to={`/projekt/${project.id}`}>
                                                        {project.name}
                                                    </Link>
                                                </div>
                                                <div
                                                    className="project_number">{`Projektnummer: ${project.alibi}`}</div>
                                            </div>
                                        </div>
                                        {isAdmin || project.role_name === "Admin" ||
                                        project.role_name === "Project Manager" ? <div className="card__right">
                                            <ul className="project__edit">
                                                <Link to={`/projekt/bearbeiten/${project.id}`}>
                                                    <li>
                                                        <FaEdit title="bearbeiten"/>
                                                    </li>
                                                </Link>
                                            </ul>
                                        </div> : (
                                            ""
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>}
        </div>
    );
}

export default Projects;
