const userDataReducer = (state = {}, action) => {
    switch (action.type) {
        case "SET_USER_DATA":
            return action.payload;
        case "DEL_USER_DATA":
            return {};
        case "UPDATE_USER":
            return {
                ...state,
                first_name: action.payload.first_name,
                last_name: action.payload.last_name,
                email: action.payload.mail,
                phone: action.payload.phone,
                company: action.payload.company,
                position: action.payload.position,
            };

        case "ADD_PROJECT":
            let insert = action.payload;
            if (!Array.isArray(insert)) {
                insert = [insert]
            }

            return {
                ...state,
                projects: [...state.projects, ...insert],
            };
        case "UPDATE_PROJECT":
            state = state.projects.filter((item) =>
                item.project_id === action.payload ? (item.inactive = true) : ""
            );
            return state;
        case "UPDATE_INACTIVE_PROJECT":
            let update = action.payload;
            let newStateobject=Object.assign({}, state.inactiveProjects)
            for(const projectId of Object.keys(update)){
                newStateobject[projectId]=update[projectId];
            }
            let formerKeys=Object.keys(newStateobject);
            if(formerKeys.length==0){
                newStateobject=update;
            }
            return {
                ...state,
                inactiveProjects: newStateobject,
            };
        case "DEACTIVATE_PROJECT":
            return {
                ...state,
                projects: state.projects.map((project) =>
                    project.project_id === action.payload
                        ? {
                            ...project,
                            Projects_Deployments: {
                                ...project.Projects_Deployments,
                                inactive: true,
                            },
                        }
                        : project
                ),
            };
        case "ACTIVATE_PROJECT":
            return {
                ...state,
                projects: state.projects.map((project) =>
                    project.project_id === action.payload
                        ? {
                            ...project,
                            Projects_Deployments: {
                                ...project.Projects_Deployments,
                                inactive: false,
                            },
                        }
                        : project
                ),
            };
        default:
            return state;
    }
};

export default userDataReducer;

/*
userData
  projects
    0
      Projects_Deployments      
    1
      Projects_Deployments

*/
