import React, {useEffect, useState} from "react";
import functions from "../../../../assets/DBCalls/functions";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {ConfirmDialog} from 'primereact/confirmdialog';
import {useHistory} from "react-router-dom";

function ContactDataTable(props) {
    const history = useHistory();

    const [stopCards, setStopCards] = useState([]);
    const [visible, setVisible] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [currentCard, setCurrentCard] = useState(null);


    const accept = () => {
        let data = {
            closed: true
        };
        functions.put('project/' + projectId + '/stopcard/' + currentCard, JSON.stringify(data), 'sicherEs').then(res => {
            if (res == true) {
                setVisible(false);
                setCurrentCard('');
                fetchStopCards(projectId);
            }

        })
    }

    const reject = () => {
        setVisible(false);
    }

    const fetchStopCards = async (id) => {
        let url = 'project/' + id + '/stopcard';
        let data = await functions.fetch(url, 'sicherEs');
        setStopCards(data);
    };

    const upDateStopCard = (openCard, id) => {

        if (openCard && props.page == 'edit') {
            setVisible(true);
            setCurrentCard(id);
        }

    }

    const statusStopcardTemplate = (rowData) => {
        let label = 'geschlossen';
        let cssClass = 'p-button-success p-button-sm py-1';
        let openCard = false;
        let handle = null;
        if (rowData.closed !== true) {
            label = 'offen';
            cssClass = 'p-button-danger p-button-sm py-1';
            openCard = true;
            if(props.page=='edit'){
                handle = () => {
                    history.push('/stopcard/info/' + rowData.project_id + '/' + rowData.id)
                }
            }

        }

        return (
            <React.Fragment>
                <Button label={label} className={cssClass}
                        onClick={handle}/>
                {/*<Button label={label} className={cssClass} onClick={() => upDateStopCard(openCard, rowData.id)}/>*/}
            </React.Fragment>
        );
    }


    const stopcardNameTemplate = (rowData) => {

        let dateDB = rowData.created_at;
        let date = new Date(dateDB);

        return (
            <React.Fragment>
                Stopkarte- {date.toLocaleDateString('de-DE')} {date.toLocaleTimeString('de-DE')}
            </React.Fragment>
        );
    }


    const createdByTemplate = (rowData) => {

        return (
            <React.Fragment>
                {rowData.created_by_user.last_name}, {rowData.created_by_user.first_name}
            </React.Fragment>
        );
    }

    const createAndDownloadPdf = async (id) => {
        await functions.fetch('project/' + id + '/stopcard/pdf/' + id, 'sicherEs').then(res => {

            const linkSource = `data:application/pdf;base64,` + res;
            const downloadLink = document.createElement("a");
            const fileName = "Stopkarte.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        })
    }

    const pdfTemplate = (rowData) => {
        let icon = rowData.pdf_available ? <a>
            <ul className="project__edit p2"><Button icon="pi pi-file-pdf"
                                                     className="p-button-rounded p-button-secondary p-button-text p-0"
                                                     aria-label="pdf" onClick={() => {
                createAndDownloadPdf(rowData.id)
            }}/></ul>
        </a> : '';
        return (
            <React.Fragment>
                {icon}
            </React.Fragment>
        );
    }

    useEffect(() => {
        setProjectId(props.id);
        fetchStopCards(props.id);
    }, []);

    return (
        <div>
            <DataTable className="card__table hovereffect" value={stopCards} responsiveLayout="scroll">
                <Column field="name" header="Name" body={stopcardNameTemplate}/>
                <Column field="name" header="" body={pdfTemplate} className="p-0"/>
                <Column field="project.name" header="Projektnamen"/>
                <Column field="rowData.created_by_user.last_name" header="Erfasser" body={createdByTemplate}/>
                <Column field="closed_at" header="Status" body={statusStopcardTemplate}/>
            </DataTable>
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)}
                           message="Wollen Sie diese Stopkarte wirklich schliessen?"
                           header="Achtung" icon="pi pi-exclamation-triangle" accept={accept} reject={reject}
                           acceptLabel={'Speichern'} rejectLabel={'Abbrechen'}/>
        </div>)
}

export default ContactDataTable;
