import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    SetSelectedPage
} from "../../../adapters/redux/actions";
import {Link, useParams, useHistory} from "react-router-dom";
import {OnlyOfficeEditor} from "./components/OnlyOfficeEditor";
import "../../../css/OnlyOffice.css"
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import functions from "../../../assets/DBCalls/functions";
import generalFunctions from "../../../assets/General/generalFunctions";
import valueMapping from "../../../assets/General/valueMapping";
import {Dropdown} from 'primereact/dropdown';
import {FaArrowCircleLeft} from "react-icons/fa";

function AddWorkType() {
    const dispatch = useDispatch();
    const {slug} = useParams();
    const [workspace, setWorkspace] = useState('');
    const [worktype, setWorktype] = useState('');
    const [workSpaces, setWorkSpaces] = useState([]);
    const [work_id, setWork_id] = useState(generalFunctions.uuidv4());
    const history = useHistory();
    useEffect(() => {
        console.log(work_id)
    }, [work_id]);

    const closeEditor = () => {
        const div = document.getElementsByClassName('onlyOfficeDiv');
        const div2 = document.getElementsByClassName('formularData');
        div[0].innerHTML = 'Dokument übernommen';
        div[0].style.height = '100px';
        div2[0].style.display = 'block';
        /*   console.log(editor,div);
           if(editor!==null){
               editor.destroyEditor();
               setEditor(null);

               div.innerHTML='';
           }
   */
    }


    const saveWorkType = async () => {
        let body = {
            "id": work_id,
            "work_type_id": workspace,
            "name": worktype,
            "project_id": slug
        };
        let url = 'work';
        setTimeout(await functions.post(url, body, 'sicherEsLive').then(res => {
            if (res.id) {
                history.goBack()
            } else {
                alert('Fehler beim speichern, versuchen Sie es bitte nochmal erneut')
            }
        }), 1000);
    }

    useEffect(async () => {

        dispatch(
            SetSelectedPage({
                title: "Tätigkeit anlegen",
                breadcrump: "Projekt / Gefährdungsbeurteilung (GBU) / Tätigkeit anlegen",
                id: "project_gbu_worktype",
            })
        );

        let workspacesApi = await valueMapping.workspace();
        setWorkSpaces(workspacesApi);
    }, []);


    return (<div>
            <div className="card">
                <div className="card__header">Neue Tätigkeit anlegen<ul className="project__edit"><li onClick={history.goBack}><FaArrowCircleLeft/></li></ul></div>
                <div className="card__body">
                    <h3>Gefährdungsbeurteilung (GBU) - Dokumentation</h3>
                </div>
                <div className="card p-2 m-0">
                    <p><u> 1.Schritt: Füllen Sie die Tätigkeit aus</u></p>
                    <div className={'onlyOfficeDiv'}><p><Button label="Einträge übernehmen" aria-label="Übernehmen"
                                                                onClick={closeEditor}/></p>
                        <OnlyOfficeEditor
                            workId={work_id}/></div>
                </div>
            </div>
            <div className="card formularData p-2">
                <u>2.Schritt: Weitere Angaben</u>
                <div className="card__body">
                    <div className="p-formgroup-inline">
                        <div className="p-field">
                            <label htmlFor="arbeitsbereich">Arbeitsbereich </label>
                            <Dropdown value={workspace} optionLabel={'name'} optionValue={'id'} options={workSpaces}
                                      onChange={(e) => setWorkspace(e.value)}/>
                        </div>

                        <div className="p-field">
                            <label htmlFor="taetigkeit">Tätigkeit </label>
                            <InputText id="taetigkeit" value={worktype} onChange={(e) => setWorktype(e.target.value)}/>
                        </div>
                        <div className="p-field">
                            <Button label="Submit" aria-label="Submit" onClick={saveWorkType}/>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default AddWorkType;
