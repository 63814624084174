import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SetSelectedPage, OpenSnackbar} from "../../../adapters/redux/actions";
import config from "../../../adapters/url_config/config";
import {Redirect} from "react-router-dom";
import {useForm} from "react-hook-form";

function CreateUser() {
    let token = localStorage.getItem("keycloak-token");
    const dispatch = useDispatch();
    const isAdmin = useSelector((state) => state.userData.admin);
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm();
    const onSubmit = (data, e) => {
        addUser(
            data.firstName,
            data.lastName,
            data.email,
            data.phone,
            data.company,
            data.position,
            data.admin
        );
        // reset();
    };

    useEffect(() => {
        dispatch(
            SetSelectedPage({
                title: "Benutzer anlegen",
                breadcrump: "Benutzer / Anlegen",
                id: "user_create",
            })
        );
    }, []);

    const createRandomId = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16).toUpperCase();
            }
        );
    };

    function addToUserDB(
        firstName,
        lastName,
        email,
        phone,
        company,
        position,
        admin,
        keycloak_id,
        username
    ) {
        const id = createRandomId();

        const requestOptions_Deployments = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                fields: {
                    id: id,
                    keycloak_id: keycloak_id,
                    username: username,
                    first_name: firstName,
                    last_name: lastName,
                    mail: email,
                    phone: phone,
                    company: company,
                    position: position,
                    admin: admin,
                    inactive: false,
                },
            }),
        };

        fetch(config.fetch_url + "create/user", requestOptions_Deployments)
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    dispatch(OpenSnackbar("User wurde hinzugefügt.", true));
                    reset();
                }
            });
    }

    function addUser(
        firstName,
        lastName,
        email,
        phone,
        company,
        position,
        admin
    ) {
        //Annahme User existiert noch nicht in keycloak -> deswegen direkt post -> conflict abfangen

        const username =
            firstName.charAt(0).toLowerCase() +
            lastName.split("-").join("").split(" ").join("").toLowerCase();

        const pw = Math.random().toString(36).substr(2, 8);
      //  console.log("username", username);
      //  console.log("pw", pw);

        const requestOptions_keyclk = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                firstname: firstName,
                lastname: lastName,
                email: email,
                username: username,
                password: pw,
                mailrecipient: email,
                mailsubject:
                    "Ihr Benutzerkonto wurde angelegt - Zugriff auf Argus erteilt",
                mailbody:
                    "Hallo " +
                    firstName +
                    " " +
                    lastName +
                    ",<br><br>Ihr Benutzerkonto wurde erfolgreich erstellt. <br>Benutzername: " +
                    username +
                    "<br>Passwort: " +
                    pw +
                    "<br><br>Das Argus Usermanagement erreichen Sie unter: <a href='https://argus.dmt-group.com/'>klick</a>." +
                    "<br><br>Zur Verwaltung Ihres Keycloak Kontos loggen Sie sich bitte unter <a href='https://rest.dmt.de/auth/realms/master/protocol/openid-connect/auth?client_id=account&redirect_uri=https%3A%2F%2Frest.dmt.de%2Fauth%2Frealms%2Fmaster%2Faccount%2Flogin-redirect?path%3Dsessions&state=0%2Ff573e6da-07ad-4105-9564-73b48c5861a1&response_type=code&scope=openid'>Keycloak Benutzerverwaltung</a> ein. " +
                    "<br><br>Bitte antworten Sie auf diese Mail nicht.",
                mailbodyhtml:
                    "Hallo " +
                    firstName +
                    " " +
                    lastName +
                    ",<br>Ihr Benutzerkonto wurde erstellt. <br>Benutzername: " +
                    username +
                    "<br>Passwort: " +
                    pw +
                    "<br>Das Argus User Management erreichen Sie unter: <a href='https://argus.dmt-group.com/'>klick</a>." +
                    "<br><br>Zur Verwaltung Ihres Keycloak Kontos loggen Sie sich bitte unter <a href='https://rest.dmt.de/auth/realms/master/protocol/openid-connect/auth?client_id=account&redirect_uri=https%3A%2F%2Frest.dmt.de%2Fauth%2Frealms%2Fmaster%2Faccount%2Flogin-redirect?path%3Dsessions&state=0%2Ff573e6da-07ad-4105-9564-73b48c5861a1&response_type=code&scope=openid'>Keycloak Benutzerverwaltung</a> ein. " +
                    "<br> <br>Bitte antworten Sie auf diese Mail nicht.",
            }),
        };

        fetch(config.fetch_url + `createKeycloakUser`, requestOptions_keyclk).then(
            (data_keyclk) => {
                //console.log(data_keyclk);
                // console.log(data_keyclk.status);

                const requestOptions = {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                };
                fetch(config.fetch_url + `getKeycloak?email=${email}`, requestOptions)
                    .then((response) => response.json())
                    .then((data_reskeyclk) => {
                        if (data_keyclk.status == "409") {
                            //conflict -> check if person is already in UserDB
                            // console.log(
                            //   "gibt es bereits in keycloak - prüfen ob er auch als User in der DB ist"
                            // );

                            const requestOptions_argus = {
                                method: "GET",
                                mode: "cors",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: "Bearer " + token,
                                },
                            };
                            fetch(
                                config.fetch_url +
                                "user/" + data_reskeyclk[0].id,
                                requestOptions_argus
                            )
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data) {
                                        //TODO:Passwort reset
                                        dispatch(
                                            OpenSnackbar(
                                                "User existiert bereits. Hinweis-Email zum Passwortreset versandt", // auch in UserDB und Keycloak -> Passwort reset
                                                false
                                            )
                                        );
                                        // console.log("Passwort reset anbieten");

                                        //Send Mail -> Allgemeiner Hinweis wo man Passwort reseten kann
                                        const requestOptions_sendMail = {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": "application/json",
                                                Authorization: "Bearer " + token,
                                            },
                                            body: JSON.stringify({
                                                recipients: [email],
                                                subject:
                                                    "Zugriff auf Ihr Keycloak Konto und Argus User Management",
                                                text:
                                                    "Hallo " +
                                                    firstName +
                                                    " " +
                                                    lastName +
                                                    ",<br>Ihr Benutzerkonto besteht bereits. Bitte besuchen Sie die <a href=https://rest.dmt.de/auth/realms/master/protocol/openid-connect/auth?client_id=account&redirect_uri=https%3A%2F%2Frest.dmt.de%2Fauth%2Frealms%2Fmaster%2Faccount%2Flogin-redirect?path%3Dsessions&state=0%2Ff573e6da-07ad-4105-9564-73b48c5861a1&response_type=code&scope=openid'>Keycloak Benutzerverwaltung</a>, um Ihr Passwort zurückzusetzen oder zu ändern. <br> <br>Bitte antworten Sie auf diese Mail nicht." +
                                                    "<br>Das Argus User Management erreichen Sie unter: <a href='https://argus.dmt-group.com/'>klick</a>." +
                                                    "<br> <br>Bitte antworten Sie auf diese Mail nicht.",
                                            }),
                                        };
                                        fetch(
                                            config.fetch_url + `sendmail`,
                                            requestOptions_sendMail
                                        ).then((dataMail) => {
                                            // console.log(dataMail);
                                        });
                                    } else {
                                        // console.log(
                                        //   "User ist bereits bei keycloak aber noch nicht im ArgusProjekt - er wird angelegt"
                                        // );
                                        addToUserDB(
                                            data_reskeyclk[0].firstName,
                                            data_reskeyclk[0].lastName,
                                            data_reskeyclk[0].email,
                                            phone,
                                            company,
                                            position,
                                            admin,
                                            data_reskeyclk[0].id,
                                            data_reskeyclk[0].username
                                        );
                                    }
                                });
                        } else {
                            //angelegt in keycloak User Db _> add to User DB Argus
                            addToUserDB(
                                data_reskeyclk[0].firstName,
                                data_reskeyclk[0].lastName,
                                data_reskeyclk[0].email,
                                phone,
                                company,
                                position,
                                admin,
                                data_reskeyclk[0].id,
                                data_reskeyclk[0].username
                            );
                        }
                    });
            }
        );
    }

    if (!isAdmin) return <Redirect to="/benutzer"/>;
    return (
        <>
            <div className="createUser__container">
                <form className="createUser__form" onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="firstname">Vorname</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.firstName && "is-invalid"}`}
                        {...register("firstName", {
                            maxLength: {
                                value: 50,
                                message: "Vorname zu lang",
                            },

                            required: "Vorname eingeben",
                            pattern: {
                                // value: /^[A-Za-z]+$/i,
                                value: /^[a-zA-Z]+(?:[\s-]+[a-zA-Z]+)*$/i,
                                message: "Nur Buchstaben erlaubt (keine Umlaute).",
                            },
                        })}
                    />
                    {errors.firstName && (
                        <div className="error-feedback">{errors.firstName.message}</div>
                    )}
                    <label htmlFor="lastName">Nachname</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.lastName && "is-invalid"}`}
                        {...register("lastName", {
                            maxLength: {
                                value: 50,
                                message: "Nachname zu lang",
                            },
                            required: "Nachname eingeben.",
                            pattern: {
                                // value: /^[A-Za-z]+$/i,
                                value: /^[a-zA-Z]+(?:[\s-]+[a-zA-Z]+)*$/i,
                                message: "Nur Buchstaben erlaubt (keine Umlaute).",
                            },
                        })}
                    />
                    {errors.lastName && (
                        <div className="error-feedback">{errors.lastName.message}</div>
                    )}
                    <label htmlFor="email">E-Mail</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.email && "is-invalid"}`}
                        {...register("email", {
                            maxLength: {
                                value: 100,
                                message: "E-Mail zu lang",
                            },
                            required: "E-Mail eingeben",
                            pattern: {
                                value:
                                    /^[a-zA-Z0-9._-]+(?:\.[a-zA-Z0-9._-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
                                // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                                message: "Ungültige E-Mail-Adresse (keine Umlaute).",
                            },
                        })}
                    />
                    {errors.email && (
                        <div className="error-feedback">{errors.email.message}</div>
                    )}
                    <label htmlFor="phone">Telefonnummer</label>
                    <input
                        type="tel"
                        autoComplete="off"
                        className={`form-control ${errors.phone && "is-invalid"}`}
                        {...register("phone", {
                            maxLength: {
                                value: 20,
                                message: "Telefonnummer zu lang",
                            },
                            required: "Telefonnummer eingeben",
                            pattern: {
                                value:
                                    /^([+](\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,10}$/im,
                                message: "Ungültige Telefonnummer",
                            },
                        })}
                    />
                    {errors.phone && (
                        <div className="error-feedback">{errors.phone.message}</div>
                    )}
                    <label htmlFor="company">Firmenname</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.company && "is-invalid"}`}
                        {...register("company", {
                            maxLength: {
                                value: 50,
                                message: "Name der Firma zu lang",
                            },
                            required: "Name der Firma eingeben.",
                        })}
                    />
                    {errors.company && (
                        <div className="error-feedback">{errors.company.message}</div>
                    )}
                    <label htmlFor="position">Positionsbezeichnung</label>
                    <input
                        type="text"
                        autoComplete="off"
                        className={`form-control ${errors.position && "is-invalid"}`}
                        {...register("position", {
                            maxLength: {
                                value: 100,
                                message: "Positionsbezeichnung zu lang",
                            },
                            required: "Positionsbezeichnung eingeben.",
                        })}
                    />
                    {errors.position && (
                        <div className="error-feedback">{errors.position.message}</div>
                    )}
                    <div>
                        <input
                            className="btn btn-primary"
                            type="submit"
                            value="Benutzer erstellen"
                        />
                    </div>
                </form>
            </div>
        </>
    );
}

export default CreateUser;
