import config from "../../adapters/url_config/config";
import {ActivateProject, DeactivateProject} from "../../adapters/redux/actions";

const getUrl = (app) => {
    let url = '';
    switch (app) {
        case 'sicherEs':
            url = config.fetchSicherEs_url;
            break;

        case 'sicherEsLive':
            url = config.fetchSicherEs_Liveurl;
            break;
        default:
            url = config.fetch_url;
            break;

    }
    return url;
}

const functions = {

    fetch: (path, app, blob) => {
        const token = localStorage.getItem("keycloak-token");
        const requestOptions = {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": blob ? "application/zip" : "application/json",
                Authorization: "Bearer " + token,
            },
        };


        return new Promise(function (resolve, reject) {
            fetch(
                getUrl(app) + path,
                requestOptions
            )
                .then((response) => blob ? response.blob() : response.json())
                .then((data) => {
                    resolve(data);
                });
        });
    },

    patch: (path, status, projectId, app) => {
        const token = localStorage.getItem("keycloak-token");
        const requestOptions_Deployments = {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                is_inactive: status ? 1 : 0,
                options: {
                    where: {
                        id: projectId,
                    },
                },
            }),
        };

        return new Promise(function (resolve, reject) {
            fetch(getUrl(app) + path, requestOptions_Deployments)
                .then((response) => response.json())
                .then((data) => {
                    resolve(data)
                });
        })
    },
    put: (path, json, app) => {
        const token = localStorage.getItem("keycloak-token");
        const requestOptions = {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: json
        };

        return new Promise(function (resolve, reject) {
            fetch(
                getUrl(app) + path,
                requestOptions
            )
                .then((response) => {
                    if (response.ok) {
                        resolve(true)
                    } else {
                        reject(response.statusText)
                    }
                });
        });
    },

    post: (path, data, app) => {

        const token = localStorage.getItem("keycloak-token");
        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data)
        };
        return new Promise(function (resolve, reject) {
            // console.log(url);
            resolve(fetch(
                getUrl(app) + path,
                requestOptions
            ).then(res => {
                const contentType = res.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return res.json()
                } else {
                    return res.text();
                }
            }));
        });
    },

    delete: (path,data, app) => {

        const token = localStorage.getItem("keycloak-token");
        let requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }

        };

        if(data){
            requestOptions.body=JSON.stringify(data)
        }
        return new Promise(function (resolve, reject) {
            // console.log(url);
            resolve(fetch(
                getUrl(app) + path,
                requestOptions
            ).then(res => {
                return res
            }));
        });
    },
}
export default functions;
