const projectsReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_PROJECT_DATA":
      return action.payload;
    case "DEL_PROJECT_DATA":
      return {};
    default:
      return state;
  }
};

export default projectsReducer;
