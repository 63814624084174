import {useEffect} from "react";
import keycloak from "../adapters/keycloak/keycloak";
import {useDispatch, useSelector} from "react-redux";
import {
    DelUserData,
    UserLoggedOut,
    ShowSidebar,
} from "../adapters/redux/actions";
import {
    SetUserData,
    UserLoggedIn,
    SetPreloadDone,
} from "../adapters/redux/actions";

import {isMobile} from "react-device-detect";
import functions from "../assets/DBCalls/functions";

function Preloading() {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const isLoggedIn = useSelector((state) => state.isLoggedIn);

    function checkDevice() {
        if (isMobile) {
            dispatch(ShowSidebar());
        }
    }

    function initKeycloak() {
        keycloak
            .init({onload: "check-sso"})
            .then((auth) => {
                if (!auth) {
                    keycloak.init({onLoad: "login-required"});
                }

                localStorage.setItem("keycloak-token", keycloak.token);
                localStorage.setItem("keycloak-refresh-token", keycloak.refreshToken);

                //Daten des erfolgreich eingeloggten Users lade

                    //Token 2534a1cb-dcdd-443d-b2ee-6efa73686487
                    /*{
            "exp": 1658403455,
            "iat": 1658403155,
            "auth_time": 1658403048,
            "jti": "153ea0ca-b982-4ebd-a55a-d18ffb01159b",
            "iss": "https://corees.dmt-group.com/realms/coreES",
            "aud": "sicherES-web",
            "sub": "2534a1cb-dcdd-443d-b2ee-6efa73686487",
            "typ": "ID",
            "azp": "sicherES-web",
            "nonce": "c5348472-278b-4322-bc1e-007dd31711d5",
            "session_state": "2af154bd-2456-4364-9169-67816d1f4018",
            "at_hash": "Tb89aQGUb_Dmk7JczNQU7A",
            "acr": "0",
            "sid": "2af154bd-2456-4364-9169-67816d1f4018",
            "email_verified": true,
            "name": "Rebecca Mueller-Siegert",
            "preferred_username": "rmuellersiegert",
            "given_name": "Rebecca",
            "family_name": "Mueller-Siegert",
            "email": "rebecca.mueller-siegert@dmt-group.com"
        }*/
                   // console.log('fetchData', keycloak.idTokenParsed.sub)

                let roles=keycloak.realmAccess.roles;
                functions.fetch('user/'+keycloak.idTokenParsed.sub).then(res=>{
                    dispatch(
                        SetUserData({
                            id: keycloak.idTokenParsed.sub,
                            first_name: keycloak.idTokenParsed.given_name,
                            last_name: keycloak.idTokenParsed.family_name,
                            email: keycloak.idTokenParsed.email,
                            keycloak_id: keycloak.idTokenParsed.sub,
                            phone: res.phone,
                            company: res.company,
                            position: res.position_company,
                            admin: roles.includes("coreES-admin"),
                            inactive: !res.enabled,
                            projects: null,
                            inactiveProjects:[]
                        })
                    );

                    dispatch(UserLoggedIn());
                })

            })
            .catch((e) => {
                console.error("Authenticated Failed", e);
                // alert("Authenticated Failed");
            });
    }

    const logout = () => {
        keycloak.logout();
        dispatch(DelUserData());
        dispatch(UserLoggedOut());
    };

    useEffect(() => {
        dispatch(SetPreloadDone());
    }, [userData, isLoggedIn]);

    useEffect(() => {
        //console.log("App mounts");
        initKeycloak();
        checkDevice();
        const interval = setInterval(() => {
            updateToken();
        }, 120000);
        return () => clearInterval(interval);
    }, []);

    function updateToken() {
        let refreshToken = localStorage.getItem("keycloak-refresh-token");
        let paramsBody =
            "client_id=" +
            keycloak.clientId +
            "&grant_type=refresh_token&refresh_token=" +
            refreshToken;

        const requestOptions = {
            method: "POST",
            mode: "cors",
            useDefaultXhrHeader: false,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: paramsBody,
        };
        try {
            fetch(
                `https://corees.dmt-group.com/realms/coreES/protocol/openid-connect/token`,
                requestOptions
            )
                .then((res) => (res.status === 200 ? res.json() : logout()))
                .then((response) => {
                    localStorage.setItem("keycloak-token", response.access_token);
                    localStorage.setItem(
                        "keycloak-refresh-token",
                        response.refresh_token
                    );
                });
        } catch (error) {
            console.log("Error Update Token - logout", error);
            logout();
        }
    }

    return null;
}

export default Preloading;
