import React from "react";
import { useSelector } from "react-redux";

function Breadcrump() {
  const current_page = useSelector((state) => state.selectedPage);

  return (
    <div className="page__breadcrump">
      <div className="page__title">{current_page.title}</div>
      <div className="page__path">{current_page.breadcrump}</div>
    </div>
  );
}

export default Breadcrump;
